import {
  ProForm,
  ProFormDatePicker,
  ProFormText,
  PageContainer,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { postLeague } from "../../../api";
import dayjs from "dayjs";
import ImageUpload from "../../../components/UploadImage";
import { useState } from "react";

const NewLeague = () => {
  const navigate = useNavigate();

  const [image, setImage] = useState();
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (values) => {
    try {
      if (!image) {
        setIsError(true);
        return;
      }

      const newValues = {
        ...values,
        startTime: dayjs(values.startTime).toISOString(),
        endTime: dayjs(values.endTime).toISOString(),
        image,
      };

      const response = await postLeague(newValues);

      if (response.status === 201) {
        navigate("/leagues");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <PageContainer title="Create new league">
      <ProForm
        name="createLeagueForm"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="English Name"
              width={"lg"}
              placeholder="Enter league name in English"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="nameAr"
              width={"lg"}
              label="Arabic Name"
              placeholder="Enter league name in Arabic"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormTextArea
              // fieldProps={inputTextAreaProps}
              name="descriptionEn"
              width={"lg"}
              label="English description"
              placeholder="Enter description in English"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English description is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormTextArea
              // fieldProps={inputTextAreaProps}
              name="descriptionAr"
              width={"lg"}
              label="Arabic description"
              placeholder="Enter description in Arabic"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic description is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDatePicker
              name="startTime"
              width="lg"
              label="Start time"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Start time is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDatePicker
              name="endTime"
              width="lg"
              label="End time"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      End time is required! My friends
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </ProForm.Group>

        <ProForm.Group>
          <ImageUpload callBackGetUrl={setImage} isError={isError} />

          {isError && <p className="error-message">Image is required!!</p>}
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewLeague;
