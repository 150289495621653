import { ProTable } from "@ant-design/pro-components";
import { getClubTickets, getClubs } from "../../api";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router";
import { Select, Spin, Typography } from "antd";
import * as dayjs from "dayjs";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../utils/sortingData";
import { useForm } from "antd/lib/form/Form";
import { STATUS_OPTIONS } from "../../utils/constant";
import ClubTicketDraw from "./ClubTicketDetailsDraw";

const { Text } = Typography;

const ClubTickets = () => {
  const { matchId } = useParams();
  const [ticketForm] = useForm();

  const [tickets, setTickets] = useState([]);
  console.log("🚀 ~ tickets:", tickets);
  const [filterTickets, setFilterTickets] = useState({
    status: "",
    ticketFreeNumber: "",
    clubId: "",
    startDate: "",
    endDate: "",
  });
  const [columnsStateMap, setColumnsStateMap] = useState({
    fullName: {
      show: false,
      order: 0,
    },
    nameEn: {
      show: false,
      order: 0,
    },
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState(null);
  const [isShowTicketDetailsDraw, setIsShowTicketDetailsDraw] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();
  const [clubs, setClubs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const showTicketDraw = () => setIsShowTicketDetailsDraw(true);
  const cancelTicketDraw = () => setIsShowTicketDetailsDraw(false);

  const fetchClubTickets = useCallback(
    async (filterParams = {}) => {
      setIsLoading(true);
      try {
        const response = await getClubTickets(
          matchId,
          currentPage,
          pageSize,
          filterTickets?.ticketFreeNumber ||
            filterTickets?.status ||
            filterTickets?.clubId ||
            filterTickets?.startDate ||
            filterTickets?.endDate
            ? filterTickets
            : filterParams
        );

        setTickets(response.results);
        setTotalItems(response.total);
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, filterTickets, matchId, pageSize]
  );

  const fetchClubs = useCallback(async () => {
    try {
      const response = await getClubs();

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));

      setClubs(customizeData);
    } catch (err) {
      console.log("err");
    }
  }, []);

  const handleSubmitFilter = (params) => {
    const formValues = ticketForm.getFieldsValue();

    setFilterTickets({
      ...filterTickets,
      ticketFreeNumber: params?.ticketFreeNumber || "",
      status:
        params?.status?.length > 0 && formValues?.status?.length > 0
          ? JSON.stringify(params?.status) || JSON.stringify(formValues?.status)
          : "",
      clubId: params?.clubId || formValues?.clubId || "",
      startDate:
        params?.createdAt?.[0] && formValues?.createdAt?.[0]
          ? dayjs(params?.createdAt?.[0]).toISOString() ??
            dayjs(formValues?.createdAt?.[0]).toISOString()
          : "",
      endDate:
        params?.createdAt?.[1] && formValues?.createdAt?.[1]
          ? dayjs(params?.createdAt?.[1]).toISOString() ??
            dayjs(formValues?.createdAt?.[1]).toISOString()
          : "",
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterTickets({
      status: "",
      ticketFreeNumber: "",
      clubId: "",
      startDate: "",
      endDate: "",
    });
    setCurrentPage(1);
  };

  const handleRowOnClick = (record, rowIndex) => {
    showTicketDraw();
    setSelectedTicket(record);
  };

  useEffect(() => {
    fetchClubTickets();
  }, [fetchClubTickets]);

  useEffect(() => {
    fetchClubs();
  }, [fetchClubs]);

  const columns = [
    {
      title: "Ticket Number",
      dataIndex: "ticketFreeNumber",
      sorter: (current, next) =>
        SortingText(current?.ticketFreeNumber, next?.ticketFreeNumber),
    },
    {
      title: "Status",
      dataIndex: "status",
      valueType: "select",
      sorter: (current, next) => SortingText(current.status, next.status),
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }

        return (
          <Select
            {...fieldProps}
            mode="multiple"
            allowClear
            placeholder="Please select"
            options={STATUS_OPTIONS}
          />
        );
      },
    },
    {
      title: "Payment status",
      dataIndex: "paymentStatus",
      search: false,
      sorter: (current, next) =>
        SortingNumber(current?.paymentStatus, next?.paymentStatus),
      valueEnum: {
        WAITING_FOR_PAYMENT: {
          text: "WAITING_FOR_PAYMENT",
        },
        SOLD: {
          text: "SOLD",
        },
        EXPIRED: {
          text: "EXPIRED",
        },
      },
    },
    {
      title: "Club",
      dataIndex: "clubId",
      sorter: (current, next) =>
        SortingText(current?.club?.nameAr, next?.club?.nameAr),
      render: (_, record) => <Typography>{record?.club?.nameAr}</Typography>,
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }

        return (
          <Select
            {...fieldProps}
            allowClear
            placeholder="Please select"
            options={clubs}
          />
        );
      },
    },
    {
      title: "Club(En)",
      dataIndex: "nameEn",
      search: false,
      sorter: (current, next) =>
        SortingText(current?.club?.nameEn, next?.club?.nameEn),
      render: (_, record) => {
        return <Text>{record?.club?.nameEn ? record?.club?.nameEn : "-"}</Text>;
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      search: false,
      sorter: (current, next) => SortingNumber(current?.price, next?.price),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) =>
        SortingDate(current?.createdAt, next?.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.updatedAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
  ];

  return (
    <>
      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" spin />,
          spinning: isLoading,
        }}
        columns={columns}
        dataSource={tickets}
        request={async ({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);
          setPageSize(pageSize);
          // await fetchTickets();
          return {
            // success,
            // total,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 1400 }}
        search={{
          labelWidth: "auto",
          form: ticketForm,
          // defaultCollapsed: false,
        }}
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: () => handleRowOnClick(record, rowIndex),
          };
        }}
      ></ProTable>

      <ClubTicketDraw
        open={isShowTicketDetailsDraw}
        cancel={cancelTicketDraw}
        ticketData={selectedTicket}
        tickets={tickets}
        setTickets={setTickets}
      />
    </>
  );
};

export default ClubTickets;
