import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { deleteLeague, getLeagues } from "../../api";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { Button, Modal, Typography, Avatar } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  DeleteFilled,
  FileTextFilled,
  EditFilled,
  ExclamationCircleFilled,
  UndoOutlined,
} from "@ant-design/icons";
import { SortingDate, SortingText } from "../../utils/sortingData";
import { filterDateTimeBetween, filterText } from "../../utils/filterData";

const { Text } = Typography;

const Leagues = () => {
  const navigate = useNavigate();

  const [leagues, setLeagues] = useState([]);
  const [filterLeagues, setFilterLeagues] = useState(leagues);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsShowModal(true);
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  const fetchLeagues = useCallback(async () => {
    try {
      const response = await getLeagues();

      setLeagues(response);
      setFilterLeagues(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const handleSubmitModal = async () => {
    try {
      const response = selectedRecord?.deleted
        ? console.log("Restored record")
        : await deleteLeague(selectedRecord?.id);

      if (response.status === 200) {
        await fetchLeagues();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const newParams = {
      ...params,
      nameEn: params?.nameEn || "",
      nameAr: params?.nameAr || "",
      descriptionEn: params?.descriptionEn || "",
      descriptionAr: params?.descriptionAr || "",
      startTime: params?.createdAt?.[0],
      endTime: params?.createdAt?.[1],
    };

    const newFilterLeagues = filterLeagues.filter((league) => {
      const filterEnglishName = filterText(league?.nameEn, newParams?.nameEn);
      const filterArabicName = filterText(league?.nameAr, newParams?.nameAr);
      const filterEnglishDescription = filterText(
        league?.descriptionEn,
        newParams?.descriptionEn
      );
      const filterArabicDescription = filterText(
        league?.descriptionAr,
        newParams?.descriptionAr
      );
      const filterCreatedAt = filterDateTimeBetween(
        league?.createdAt,
        newParams?.startTime,
        newParams?.endTime
      );

      return (
        filterEnglishName &&
        filterArabicName &&
        filterEnglishDescription &&
        filterArabicDescription &&
        filterCreatedAt
      );
    });

    setLeagues(newFilterLeagues);
  };

  const handleResetFilter = () => setLeagues(filterLeagues);

  useEffect(() => {
    fetchLeagues();
  }, [fetchLeagues]);

  const tableColumns = [
    {
      title: "Image",
      dataIndex: "image",
      search: false,
      render: (_, record) => {
        return <Avatar src={record?.image} />;
      },
    },
    {
      title: "English Name",
      dataIndex: "nameEn",
      width: 200,
      sorter: (current, next) => SortingText(current.nameEn, next.nameEn),
    },
    {
      title: "Arabic Name",
      dataIndex: "nameAr",
      width: 200,
      sorter: (current, next) => SortingText(current.nameAr, next.nameAr),
    },
    {
      title: "English Description",
      dataIndex: "descriptionEn",
      width: 300,
      sorter: (current, next) =>
        SortingText(current.descriptionEn, next.descriptionEn),
    },
    {
      title: "Arabic Description",
      dataIndex: "descriptionAr",
      width: 300,
      sorter: (current, next) =>
        SortingText(current.descriptionAr, next.descriptionAr),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 100,
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      search: false,
      width: 100,
      valueType: "dateTimeRange",
      hideInTable: true,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.updatedAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      title: "Actions",
      width: 100,
      search: false,
      render: (_, record) => [
        <div className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/leagues/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/leagues/${record?.id}/edit`)}
          />

          {record?.deleted ? (
            <Button
              className="icon-button"
              icon={<UndoOutlined />}
              onClick={() => showModal(record)}
            />
          ) : (
            <Button
              className="icon-button"
              icon={<DeleteFilled />}
              onClick={() => showModal(record)}
            />
          )}
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Leagues">
      <Modal
        open={isShowModal}
        onOk={() => handleSubmitModal()}
        onCancel={handleCancel}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          {selectedRecord?.deleted ? (
            <p>Are you sure to restore this record?</p>
          ) : (
            <p>Are you sure to delete this record?</p>
          )}
        </div>
      </Modal>

      <ProTable
        rowKey="id"
        scroll={{ x: 1500 }}
        columns={tableColumns}
        dataSource={leagues}
        request={({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);

          return {
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/leagues/new");
            }}
            type="primary"
          >
            New League
          </Button>,
        ]}
        search={{
          labelWidth: "auto",
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Leagues;
