import React from "react";
import { Typography } from "antd";
import { ProColumns } from "@ant-design/pro-components";
import { ProTable, TableDropdown } from "@ant-design/pro-components";

const { Title } = Typography;

const Home = () => {
  return <ProTable>h1. Ant Design</ProTable>;
};

export default Home;
