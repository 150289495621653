import { PageContainer } from "@ant-design/pro-layout";
import MatchTickets from "./tickets";
import ClubTickets from "./clubTickets";

const Tickets = () => {
  return (
    <PageContainer
      title="Tickets"
      tabList={[
        {
          tab: "Normal tickets",
          key: "tickets",
          children: <MatchTickets />,
        },
        {
          tab: "Club tickets",
          key: "club",
          children: <ClubTickets />,
        },
      ]}
    ></PageContainer>
  );
};

export default Tickets;
