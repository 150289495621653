import { Button, Drawer, Modal, Spin, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import {
  getClubTicketCheckedInHistories,
  getGatesByStadium,
  postCheckInStatus,
} from "../../../api";
import { ProForm, ProFormSelect, ProTable } from "@ant-design/pro-components";
import CustomizePagination from "../../../components/Pagination";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { SortingDate, SortingText } from "../../../utils/sortingData";

const { Text } = Typography;

const ClubTicketDraw = ({
  open,
  ticketData,
  cancel = () => {},
  tickets,
  setTickets,
}) => {
  console.log("🚀 ~ ticketData:", ticketData);
  const [gateForm] = useForm();
  const [historyForm] = useForm();

  const [clubTicketHistories, setClubTicketHistories] = useState([]);
  const [filterClubTicketHistories, setFilterClubTicketHistories] = useState({
    startDate: "",
    endDate: "",
  });
  const [isShowCheckInModal, setIsShowCheckInModal] = useState(false);
  const [gates, setGates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowCheckInModal = () => setIsShowCheckInModal(true);
  const handleCancelCheckInModal = () => {
    gateForm.setFieldsValue({ gateId: null });
    setIsShowCheckInModal(false);
  };

  const fetchClubTicketCheckedInHistories = useCallback(
    async (filterParams = {}) => {
      setIsLoading(true);
      try {
        const response = await getClubTicketCheckedInHistories(
          ticketData?.id,
          currentPage,
          pageSize,
          filterClubTicketHistories?.startDate ||
            filterClubTicketHistories?.endDate
            ? filterClubTicketHistories
            : filterParams
        );

        setClubTicketHistories(response.results);
        setTotalItems(response.total);
      } catch (error) {
        console.log("Error", error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, filterClubTicketHistories, pageSize, ticketData?.id]
  );

  const fetchGatesByStadium = useCallback(async (stadiumId) => {
    try {
      const response = await getGatesByStadium(stadiumId);

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));

      setGates(customizeData);
    } catch (err) {
      console.log("err", err);
    }
  }, []);

  const handleSubmitHistoryFilter = (params) => {
    const formValues = historyForm.getFieldsValue();

    setFilterClubTicketHistories({
      ...filterClubTicketHistories,
      startDate:
        params?.checkInTime?.[0] && formValues?.checkInTime?.[0]
          ? dayjs(params?.checkInTime?.[0]).toISOString() ??
            dayjs(formValues?.checkInTime?.[0]).toISOString()
          : "",
      endDate:
        params?.checkInTime?.[1] && formValues?.checkInTime?.[1]
          ? dayjs(params?.checkInTime?.[1]).toISOString() ??
            dayjs(formValues?.checkInTime?.[1]).toISOString()
          : "",
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterClubTicketHistories({
      startDate: "",
      endDate: "",
    });
    setCurrentPage(1);
  };

  const handleSubmitModal = async () => {
    const params = gateForm.getFieldsValue();
    const newParams = {
      encryptedCheckInCode: ticketData?.id,
      checkInTime: dayjs().toISOString(),
      gateId: params?.gateId,
    };
    let newTickets = [...tickets];

    try {
      const response = await postCheckInStatus(newParams, true);

      const filterTicketIndex = newTickets.findIndex((ticket) =>
        response?.id.toString().includes(".")
          ? response?.id.split(".")?.[1] === ticket?.id
          : -1
      );

      if (filterTicketIndex !== -1) {
        newTickets[filterTicketIndex].status = response.status;

        setTickets(newTickets);
      }

      fetchClubTicketCheckedInHistories();
      handleCancelCheckInModal();
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    if (ticketData && ticketData?.id) {
      fetchClubTicketCheckedInHistories();
    }
  }, [fetchClubTicketCheckedInHistories, ticketData]);

  const columns = [
    {
      title: "Ticket Number",
      dataIndex: ["ticketFree", "ticketFreeNumber"],
      hideInTable: true,
      search: false,
      sorter: (current, next) =>
        SortingText(current.ticketNumber, next.ticketNumber),
    },
    {
      title: "Gate name",
      dataIndex: ["gate", "nameAr"],
      search: false,
      sorter: (current, next) => SortingText(current.status, next.status),
    },
    {
      title: "Check in time",
      dataIndex: "checkInTime",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
      },
      sorter: (current, next) =>
        SortingDate(current?.checkInTime, next?.checkInTime),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record?.checkInTime)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
      },
      search: false,
      sorter: (current, next) =>
        SortingDate(current?.createdAt, next?.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record?.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
  ];

  return (
    <Drawer
      title={`Club ticket details [${ticketData?.ticketFreeNumber}]`}
      width={700}
      closable={false}
      onClose={() => cancel()}
      open={open}
    >
      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" spin />,
          spinning: isLoading,
        }}
        columns={columns}
        dataSource={clubTicketHistories}
        request={async ({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);
          setPageSize(pageSize);

          return {
            // success,
            // total,
          };
        }}
        onSubmit={(params) => handleSubmitHistoryFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 1000 }}
        // search={{
        //   labelWidth: "auto",
        //   form: historyForm,
        // }}
        search={false}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
      ></ProTable>

      <Button
        type="primary"
        onClick={() => {
          fetchGatesByStadium(ticketData?.match?.stadiumId);

          handleShowCheckInModal();
        }}
      >
        Check in
      </Button>

      <Modal
        open={isShowCheckInModal}
        onOk={() => handleSubmitModal()}
        onCancel={handleCancelCheckInModal}
        centered
        footer={null}
      >
        <div className="check-in-wrapper">
          <ProForm
            form={gateForm}
            name="createMatchForm"
            layout="horizontal"
            onFinish={handleSubmitModal}
          >
            <ProForm.Group>
              <div className="form-fields-wrapper">
                <ProFormSelect
                  name="gateId"
                  width={"lg"}
                  label="Gate ID"
                  placeholder="Select gate id"
                  rules={[
                    {
                      required: true,
                      message: () => (
                        <p className="error-message">
                          Gate ID is required! My friends
                        </p>
                      ),
                    },
                  ]}
                  options={gates}
                />
              </div>
            </ProForm.Group>
          </ProForm>
        </div>
      </Modal>
    </Drawer>
  );
};

export default ClubTicketDraw;
