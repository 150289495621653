import React, { useCallback } from "react";
import {
  ProForm,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import { useNavigate, useParams } from "react-router";
import { postGate } from "../../../api";
import { message } from "antd";

const NewGate = () => {
  const [form] = ProForm.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { stadiumId } = useParams();

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const response = await postGate(stadiumId, values);

        if (response.status === 201) {
          navigate(`/stadiums/${stadiumId}`);
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [stadiumId, navigate]
  );

  return (
    <PageContainer title="New Gate">
      {contextHolder}
      <ProForm
        name="createGate"
        layout="horizontal"
        form={form}
        onFinish={handleSubmit}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="Name in English"
              width={"lg"}
              rules={[{ required: true }]}
            />

            <ProFormText
              name="nameAr"
              label="Name in Arabic"
              width={"lg"}
              rules={[{ required: true }]}
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewGate;
