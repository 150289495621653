import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getLeagueData } from "../../../api";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Card, Divider, Image } from "antd";

const LeagueDetails = () => {
  const { id } = useParams();

  const [leagueData, setLeagueData] = useState();

  const fetchLeagueData = useCallback(async () => {
    try {
      const response = await getLeagueData(id);

      setLeagueData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchLeagueData();
  }, [fetchLeagueData]);

  return (
    <>
      {leagueData && (
        <PageContainer title={`League details`}>
          <Card title="League Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="League ID"
                valueType="text"
                span={2}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {leagueData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English name" valueType="text">
                {leagueData?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic name" valueType="text">
                {leagueData?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="English description"
                valueType="text"
              >
                {leagueData?.descriptionEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic description" valueType="text">
                {leagueData?.descriptionAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Slug" valueType="text">
                {leagueData?.slug}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="League Open Time">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Start time">
                {dayjs(leagueData?.startTime).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="End time">
                {dayjs(leagueData?.endTime).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="League Logo">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Created at">
                {dayjs(leagueData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Logo">
                <Image width={150} src={leagueData?.image} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default LeagueDetails;
