import React, { useCallback, useEffect, useState } from "react";
import { Spin, Typography } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import { getOTPs } from "../../api";
import dayjs from "dayjs";

import { SortingText, SortingDate } from "../../utils/sortingData";
import { SortingNumber } from "../../utils/sortingData";
import useEventEnter from "../../utils/useEventEnter";
import { useForm } from "antd/lib/form/Form";

const { Text } = Typography;

const OTPs = () => {
  const [form] = useForm();

  const [data, setData] = useState([]);
  console.log("🚀 ~ data:", data);
  const [filterOTPs, setFilterOTPs] = useState({
    code: "",
    recipients: "",
    startDate: "",
    endDate: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOTPs = useCallback(
    async (filterParams = {}) => {
      setIsLoading(true);
      try {
        const response = await getOTPs(
          currentPage,
          pageSize,
          filterOTPs?.code ||
            filterOTPs.recipients ||
            filterOTPs?.startDate ||
            filterOTPs?.endDate
            ? filterOTPs
            : filterParams
        );

        setData(response?.results);
        setTotalItems(response?.total);
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, filterOTPs, pageSize]
  );

  const handleSubmitFilter = async (params) => {
    const formValues = form.getFieldsValue();

    setFilterOTPs({
      ...filterOTPs,
      code: params?.code || formValues?.code || "",
      recipients: params?.recipients || formValues?.recipients || "",
      startDate:
        params?.createdAt?.[0] && formValues?.createdAt?.[0]
          ? dayjs(params?.createdAt?.[0]).toISOString() ??
            dayjs(formValues?.createdAt?.[0]).toISOString()
          : "",
      endDate:
        params?.createdAt?.[1] && formValues?.createdAt?.[1]
          ? dayjs(params?.createdAt?.[1]).toISOString() ??
            dayjs(formValues?.createdAt?.[1]).toISOString()
          : "",
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterOTPs({
      code: "",
      recipients: "",
      startDate: "",
      endDate: "",
    });
    setCurrentPage(1);
  };

  useEventEnter(handleSubmitFilter);

  useEffect(() => {
    fetchOTPs();
  }, [fetchOTPs]);

  const tableColumns = [
    {
      key: "code",
      title: "Code",
      dataIndex: "code",
      sorter: (current, next) => SortingText(current?.code, next?.code),
    },
    {
      key: "recipients",
      title: "Recipients",
      dataIndex: "recipients",
      sorter: (current, next) =>
        SortingNumber(current?.recipients, next?.recipients),
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record?.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
  ];

  return (
    <PageContainer title="All OTPs">
      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" spin />,
          spinning: isLoading,
        }}
        request={async ({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);
          // await fetchOTPs(current, pageSize);
          setPageSize(pageSize);

          return {
            data,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        // scroll={{ x: 1400 }}
        columns={tableColumns}
        dataSource={data}
        search={{
          labelWidth: "auto",
          form: form,
        }}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default OTPs;
