import {
  ProForm,
  ProFormSelect,
  PageContainer,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { getUserData, patchUser } from "../../../api";
import { USER_ROLES } from "../../../utils/constant";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userData, setUserData] = useState();

  const fetchUserData = useCallback(async () => {
    try {
      const response = await getUserData(id);

      setUserData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = {
        ...{},
        dateOfBirth: userData?.dateOfBirth,
        email: userData?.email,
        fullName: userData?.fullName,
        gender: userData?.gender,
        phoneNumber: userData?.phoneNumber,
        roles: values?.roles,
        isArgreeToTerm: true,
        isActive: values?.isActive,
      };

      const response = await patchUser(id, newParams);

      if (response.status === 200) {
        navigate("/users");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <PageContainer title="Edit user">
      {userData && (
        <ProForm
          name="createUserForm"
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={userData}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormSelect
                name="roles"
                width={"lg"}
                label="Role"
                placeholder="Enter role"
                options={USER_ROLES}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Role is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <div id="switch-user" className="switch-button">
                <ProFormSwitch name="isActive" label="Is active?" />
              </div>
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditUser;
