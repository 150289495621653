import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getGateData, getStadiumData } from "../../../api";
import {
  PageContainer,
  ProDescriptions,
  ProTable,
} from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Button, Card, Divider, Image, Typography } from "antd";
import { SortingDate, SortingText } from "../../../utils/sortingData";

const StadiumDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [stadiumData, setStadiumData] = useState([]);
  const [gateData, setGateData] = useState([]);

  const fetchStadiumData = useCallback(async () => {
    try {
      const response = await getStadiumData(id);

      setStadiumData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const fetchGateData = useCallback(async () => {
    try {
      const response = await getGateData(id);

      setGateData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  useEffect(() => {
    fetchStadiumData();
    fetchGateData();
  }, [fetchGateData, fetchStadiumData]);

  return (
    <>
      {stadiumData && (
        <PageContainer title={`Stadium details`}>
          <Card title="Stadium Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Stadium ID"
                valueType="text"
                span={2}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {stadiumData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English name" valueType="text">
                {stadiumData?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic name" valueType="text">
                {stadiumData?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Slug" valueType="text">
                {stadiumData?.slug}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Stadium Logo">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Created at">
                {dayjs(stadiumData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Logo">
                <Image width={150} src={stadiumData?.logo} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card
            title="Gates"
            actions={[
              <Button
                key="submit"
                type="primary"
                onClick={() => navigate("gates/new")}
              >
                Add gate
              </Button>,
            ]}
          >
            {gateData && (
              <ProTable
                columns={[
                  {
                    title: "Name in English",
                    dataIndex: "nameEn",
                    sorter: (current, next) =>
                      SortingText(current?.nameEn, next?.nameEn),
                  },
                  {
                    title: "Name in Arabic",
                    dataIndex: "nameAr",
                    sorter: (current, next) =>
                      SortingText(current?.nameAr, next?.nameAr),
                  },
                  {
                    title: "Created at",
                    dataIndex: "createdAt",
                    sorter: (current, next) =>
                      SortingDate(current?.createdAt, next?.createdAt),
                    render: (_, record) => (
                      <Typography>
                        {dayjs(record.updatedAt)
                          .tz("Asia/Kuwait")
                          .format("YYYY/MM/DD HH:mm")
                          .toString()}
                      </Typography>
                    ),
                  },
                ]}
                dataSource={gateData}
                search={false}
                scroll={{ x: 1400 }}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: () => handleRowOnClick(record, rowIndex),
                //   };
                // }}
              />
            )}

            {/* <TicketTypeDraw
              open={isShowTicketDetailsDraw}
              cancel={cancelTicketDraw}
              ticketData={selectedTicketType}
              setTicketTypes={setTicketTypes}
            /> */}
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default StadiumDetails;
