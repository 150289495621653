import { Divider, Modal, message, notification } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  exportHistoriesCheckedIn,
  getMatch,
  getStatistics,
  syncDataToOdoo,
} from "../../../api";
import { PageContainer, ProTable } from "@ant-design/pro-components";
import { Spin, Button } from "antd";
import { useNavigate } from "react-router";
import { Card, Typography, Descriptions } from "antd";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import TicketTypeDraw from "../TicketTypeDraw";
import { isEmpty } from "lodash";
import {
  CheckCircleOutlined,
  MinusCircleOutlined,
  DownloadOutlined,
  SyncOutlined,
  EditOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
// import DuplicateCheckedInDraw from "../DuplicateCheckedInDraw";

dayjs.extend(utc);
dayjs.extend(timezone);

const MatchDetail = () => {
  const { matchId } = useParams();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, type) => {
    switch (type) {
      case 1:
        api.error({
          message: "Sync to Odoo",
          description: "Something went wrong!",
          placement,
          duration: 3,
        });
        break;

      default:
        api.success({
          message: "Sync to Odoo",
          description: "Sync match data to Odoo successfully.",
          placement,
          duration: 3,
        });
        break;
    }
  };

  const [match, setMatch] = useState(null);
  const [teamHost, setTeamHost] = useState(null);
  const [teamGuest, setTeamGuest] = useState(null);
  const [ticketTypes, setTicketTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isShowTicketDetailsDraw, setIsShowTicketDetailsDraw] = useState(false);
  const [isShowDuplicateCheckedInDraw, setIsShowDuplicateCheckedInDraw] =
    useState(false);
  const [selectedTicketType, setSelectedTicketType] = useState();
  const [columnsStateMap, setColumnsStateMap] = useState({
    nameEn: {
      show: false,
      order: 0,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [statistics, setStatistics] = useState([]);
  const [totalTicketsByStatus, setTotalTicketsByStatus] = useState({
    sold: 0,
    expired: 0,
    waitingForPayment: 0,
  });
  const [isShowExportModal, setIsShowExportModal] = useState(false);

  const showTicketDraw = () => setIsShowTicketDetailsDraw(true);
  const cancelTicketDraw = () => setIsShowTicketDetailsDraw(false);

  const showDuplicateCheckedInDraw = () =>
    setIsShowDuplicateCheckedInDraw(true);
  const cancelDuplicateCheckedInDraw = () =>
    setIsShowDuplicateCheckedInDraw(false);

  const showExportModal = () => setIsShowExportModal(true);
  const cancelExportModal = () => setIsShowExportModal(false);

  const fetchMatch = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getMatch(matchId);

      setMatch(response);
      setTeamHost(response?.clubs.find((club) => club?.type === "HOST"));
      setTeamGuest(response?.clubs.find((club) => club?.type === "GUEST"));
      setTicketTypes(response?.ticketTypes);
    } catch (err) {
      setMatch(null);
      message.error(err);
    } finally {
      setLoading(false);
    }
  }, [matchId]);

  const fetchStatistics = useCallback(async () => {
    try {
      let collectTotalTickets = {
        sold: 0,
        expired: 0,
        waitingForPayment: 0,
      };
      const response = await getStatistics(matchId);

      response?.normalTicket.forEach((item) => {
        collectTotalTickets.sold += item?.statistics?.sold;
        collectTotalTickets.expired += item?.statistics?.expired;
        collectTotalTickets.waitingForPayment +=
          item?.statistics?.waitForPayment;
      });

      setTotalTicketsByStatus(collectTotalTickets);
      setStatistics(response);
    } catch (error) {
      console.log(error);
    }
  }, [matchId]);

  const handleRowOnClick = (record, rowIndex) => {
    showTicketDraw();
    setSelectedTicketType(record);
  };

  const handleSyncToOdoo = async () => {
    let response = null;

    try {
      setIsLoading(true);
      response = await syncDataToOdoo(matchId);

      if (response?.statusCode === 200) {
        openNotification("bottomLeft");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
      if (!response) {
        openNotification("bottomLeft", 1);
      }
    }
  };

  const handleExportToExcel = useCallback(async () => {
    setIsShowExportModal(false);

    try {
      const response = await exportHistoriesCheckedIn(matchId);
      const blob = response.data;
      const blobUrl = URL.createObjectURL(blob);
      const currentTime = dayjs()
        .tz("Asia/Kuwait")
        .format("YYYY-MM-DD-HH_mm_ss")
        .toString();

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `check-in-histories-${matchId}-${currentTime}`;
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.log("Export Excel Fail With Error---->", error);
    }
  }, [matchId]);

  useEffect(() => {
    fetchMatch();
    fetchStatistics();
  }, [fetchMatch, fetchStatistics, matchId]);

  if (loading) {
    return <Spin />;
  }

  return (
    <>
      {match && (
        <PageContainer
          key={matchId}
          loading={loading}
          extra={[
            <Button
              key="edit"
              type="primary"
              onClick={() => navigate(`/matches/${matchId}/edit`)}
              loading={isLoading}
              icon={<EditOutlined />}
            >
              Edit match
            </Button>,
            <Button
              key="sync"
              type="primary"
              onClick={() => handleSyncToOdoo()}
              loading={isLoading}
              icon={<SyncOutlined />}
            >
              Sync to Odoo
            </Button>,
            <Button
              key="export"
              type="primary"
              onClick={showExportModal}
              loading={isLoading}
              icon={<DownloadOutlined />}
            >
              Export checked in histories
            </Button>,
            <Button
              key="tickets"
              type="primary"
              onClick={() => navigate("tickets")}
            >
              Tickets
            </Button>,
          ]}
          title={`Match detail: ${match?.nameEn} - ${match?.nameAr}`}
        >
          {contextHolder}

          {teamHost && (
            <Card key="host" title="Team Host">
              <Descriptions column={1}>
                <Descriptions.Item label="Name in English">
                  {teamHost.club?.nameEn}
                </Descriptions.Item>
                <Descriptions.Item label="Name in Arabic">
                  {teamHost.club?.nameAr}
                </Descriptions.Item>
                <Descriptions.Item label="Score">
                  {teamHost?.scored}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
          <Divider />
          {teamGuest && (
            <Card key="guest" title="Team Guest">
              <Descriptions column={1}>
                <Descriptions.Item label="Name in English">
                  {teamGuest.club?.nameEn}
                </Descriptions.Item>
                <Descriptions.Item label="Name in Arabic">
                  {teamGuest.club?.nameAr}
                </Descriptions.Item>
                <Descriptions.Item label="Score">
                  {teamGuest?.scored}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
          <Divider />
          {match && (
            <Card key="matchInfo" title="Match Information">
              <Descriptions column={1}>
                <Descriptions.Item label="Start time">
                  {dayjs(match.startTime)
                    .tz("Asia/Kuwait")
                    .format("YYYY/MM/DD HH:mm")
                    .toString()}
                </Descriptions.Item>

                <Descriptions.Item label="End time">
                  {dayjs(match.endTime)
                    .tz("Asia/Kuwait")
                    .format("YYYY/MM/DD HH:mm")
                    .toString()}
                </Descriptions.Item>

                <Descriptions.Item label="Open sale date">
                  {dayjs(match.openForTicket)
                    .tz("Asia/Kuwait")
                    .format("YYYY/MM/DD HH:mm")
                    .toString()}
                </Descriptions.Item>

                <Descriptions.Item label="Stadium">
                  {`${match.stadium?.nameEn} - ${match.stadium?.nameAr}`}
                </Descriptions.Item>

                <Descriptions.Item label="Slug">
                  {match?.slug}
                </Descriptions.Item>

                <Descriptions.Item label="Status">
                  {match?.status}
                </Descriptions.Item>

                <Descriptions.Item label="Use crowd handler?">
                  {match?.useCrowdHandler.toString()}
                </Descriptions.Item>

                <Descriptions.Item label="Crowd handler link">
                  {match?.crowdhandlerLink ?? "-"}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
          <Divider />
          <Card
            // key="statistical"
            title="Statistical"
            className="statistical-wrapper"
          >
            <div className="statistical-container">
              {statistics?.normalTicket?.map((item) => {
                return (
                  <Card
                    key={item?.type}
                    type="inner"
                    title={item?.type}
                    className={
                      item?.type === "GENERAL"
                        ? "statistical-full"
                        : "statistical-grid"
                    }
                  >
                    <Descriptions
                      column={1}
                      labelStyle={{
                        width: "260px",
                      }}
                    >
                      <Descriptions.Item label="Tickets sold">
                        {item?.statistics?.sold}
                      </Descriptions.Item>
                      <Descriptions.Item label="Tickets expired">
                        {item?.statistics?.expired}
                      </Descriptions.Item>
                      <Descriptions.Item label="Tickets waiting for payment">
                        {item?.statistics?.waitForPayment}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total checked in">
                        {item?.statistics?.totalCheckIn}
                      </Descriptions.Item>
                      <Descriptions.Item label="Total duplicate checked in">
                        {item?.statistics?.duplicateCheckIn}
                      </Descriptions.Item>
                    </Descriptions>
                  </Card>
                );
              })}

              <Card
                key="statisticalSummary"
                type="inner"
                title="SUMMARY"
                className="statistical-full"
              >
                <Descriptions
                  column={1}
                  labelStyle={{
                    width: "260px",
                  }}
                >
                  <Descriptions.Item label="Total tickets sold">
                    {totalTicketsByStatus?.sold}
                  </Descriptions.Item>

                  <Descriptions.Item label="Total tickets expired">
                    {totalTicketsByStatus?.expired}
                  </Descriptions.Item>

                  <Descriptions.Item label="Total tickets waiting for payment">
                    {totalTicketsByStatus?.waitingForPayment}
                  </Descriptions.Item>

                  <Descriptions.Item label="Total checked in">
                    {statistics?.totalCheckIn}
                  </Descriptions.Item>

                  <Descriptions.Item
                    label="Duplicate checked in"
                    labelStyle={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div className="duplicate-field">
                      {statistics?.duplicateCheckIn}

                      {/* <Button
                        type="primary"
                        onClick={showDuplicateCheckedInDraw}
                      >
                        Details
                      </Button> */}
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </div>
          </Card>
          <Divider />
          <Card
            key="ticketTypes"
            title="Ticket Types"
            actions={[
              <Button
                key="submit"
                type="primary"
                onClick={() => navigate("ticket-types/new")}
              >
                Add ticket type
              </Button>,
            ]}
          >
            {ticketTypes && (
              <ProTable
                rowKey="id"
                columns={[
                  {
                    title: "English name",
                    dataIndex: "nameEn",
                  },
                  {
                    title: "Arabic name",
                    dataIndex: "nameAr",
                  },
                  {
                    title: "Club Name",
                    render: (_, record) => (
                      <Typography>
                        {record?.club?.nameEn} - {record?.club?.nameEn}
                      </Typography>
                    ),
                  },
                  {
                    title: "Price",
                    dataIndex: "price",
                  },
                  {
                    title: "Override price",
                    dataIndex: "netPrice",
                  },
                  {
                    title: "Limit buying tickets",
                    dataIndex: "limitForUser",
                  },
                  {
                    title: "Available Tickets",
                    dataIndex: "remainingTicketQuantity",
                  },
                  {
                    title: "Enter Gates",
                    dataIndex: "gates",
                    render: (_, record) => {
                      if (!isEmpty(record.gates)) {
                        const gate = record.gates[0].gate;

                        return (
                          <Typography>
                            {gate.nameAr} - {gate.nameEn}
                          </Typography>
                        );
                      }

                      return <Typography>-</Typography>;
                    },
                  },
                  {
                    title: "Status",
                    dataIndex: "ticketTypeStatus",
                  },
                  {
                    title: "Is deleted",
                    dataIndex: "hided",
                    render: (_, record) => {
                      return record?.hided ? (
                        <CheckCircleOutlined
                          style={{
                            color: "green",
                          }}
                        />
                      ) : (
                        <MinusCircleOutlined
                          style={{
                            color: "red",
                          }}
                        />
                      );
                    },
                  },
                  {
                    title: "Updated at",
                    dataIndex: "updatedAt",
                    render: (_, record) => (
                      <Typography>
                        {dayjs(record.updatedAt)
                          .tz("Asia/Kuwait")
                          .format("YYYY/MM/DD HH:mm")
                          .toString()}
                      </Typography>
                    ),
                  },
                ]}
                dataSource={ticketTypes}
                search={false}
                scroll={{ x: 1400 }}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: () => handleRowOnClick(record, rowIndex),
                  };
                }}
                columnsState={{
                  value: columnsStateMap,
                  onChange: setColumnsStateMap,
                }}
              />
            )}

            {/* <DuplicateCheckedInDraw
              open={isShowDuplicateCheckedInDraw}
              cancel={cancelDuplicateCheckedInDraw}
            /> */}

            <TicketTypeDraw
              open={isShowTicketDetailsDraw}
              cancel={cancelTicketDraw}
              ticketData={selectedTicketType}
              setTicketTypes={setTicketTypes}
            />
          </Card>

          <Modal
            open={isShowExportModal}
            onOk={() => handleExportToExcel()}
            onCancel={cancelExportModal}
            centered
          >
            <div className="delete-content">
              <ExclamationCircleFilled style={{ fontSize: "48px" }} />
              <p>Do you want to export checked in histories?</p>
            </div>
          </Modal>
        </PageContainer>
      )}
    </>
  );
};

export default MatchDetail;
