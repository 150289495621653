import {
  ProForm,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormDateTimePicker,
  PageContainer,
  ProFormDigit,
} from "@ant-design/pro-components";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import {
  getClubs,
  getLeagues,
  getStadiums,
  getMatch,
  patchMatch,
} from "../../../api";
import dayjs from "dayjs";

import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import { MATCH_STATUSES } from "../../../utils/constant";
import { useForm } from "antd/lib/form/Form";

const EditMatch = () => {
  const { matchId } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();

  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Asia/Kuwait");

  const [matchData, setMatchData] = useState();
  const [clubs, setClubs] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [status, setStatus] = useState(null);
  const [useCrowdHandler, setUseCrowdHandler] = useState(false);

  const handleSubmit = async (values) => {
    console.log("🚀 ~ values:", values);
    try {
      const response = await patchMatch(matchId, {
        ...values,
        startTime: values.startTime
          ? dayjs
              .tz(values.startTime, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        endTime: values.endTime
          ? dayjs
              .tz(values.endTime, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        openForTicket: values.openForTicket
          ? dayjs
              .tz(values.openForTicket, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        crowdhandlerLink: useCrowdHandler ? values.crowdhandlerLink : null,
      });
      if (response.status === 200) {
        navigate(`/matches/${matchId}`);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchMatchData = useCallback(async () => {
    try {
      const response = await getMatch(matchId);
      let newObject = {};

      response?.clubs.forEach((club) => {
        if (club.type === "HOST") {
          return (newObject = {
            ...newObject,
            teamHostId: club?.clubId,
            scoredHost: club?.scored,
          });
        }

        return (newObject = {
          ...newObject,
          teamGuestId: club?.clubId,
          scoredGuest: club?.scored,
        });
      });

      const customizeData = { ...response, ...newObject };

      setMatchData(customizeData);
    } catch (error) {
      console.log("Error", error);
    }
  }, [matchId]);

  const fetchClubs = useCallback(async () => {
    try {
      const response = await getClubs();

      const customizeData = response.map((item) => ({
        value: item.id,
        label: item.nameEn,
      }));

      setClubs(customizeData);
    } catch (err) {
      console.log("err");
    }
  }, []);

  const fetchLeagues = useCallback(async () => {
    try {
      const response = await getLeagues();

      const customizeData = response.map((item) => ({
        value: item.id,
        label: item.nameEn,
      }));

      setLeagues(customizeData);
    } catch (err) {
      console.log("err");
    }
  }, []);

  const fetchStadiums = useCallback(async () => {
    try {
      const response = await getStadiums();

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));
      setStadiums(customizeData);
    } catch (err) {
      console.log("err");
    }
  }, []);

  useEffect(() => {
    fetchClubs();
    fetchLeagues();
    fetchStadiums();
    fetchMatchData();
  }, [fetchClubs, fetchLeagues, fetchMatchData, fetchStadiums]);

  useEffect(() => {
    if (matchData) {
      setStatus(matchData?.status);
      setUseCrowdHandler(matchData?.useCrowdHandler);
    }
  }, [matchData]);

  return (
    <PageContainer title="Edit match" className="match-wrapper">
      {matchData && (
        <ProForm
          form={form}
          name="editMatchForm"
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={{
            ...matchData,
            startTime: dayjs
              .utc(matchData?.startTime)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm"),
            endTime: matchData.endTime
              ? dayjs
                  .utc(matchData?.endTime)
                  .utcOffset(3)
                  .format("YYYY/MM/DD HH:mm")
              : null,
            openForTicket: dayjs
              .utc(matchData?.openForTicket)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm"),
          }}
        >
          <ProForm.Group direction="vertical">
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="English Name"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English name is required! My friends
                      </p>
                    ),
                  },
                ]}
                placeholder="Enter match name in English"
              />

              <ProFormText
                name="nameAr"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic name is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Arabic Name"
                placeholder="Enter match name in Arabic"
              />

              <ProFormSelect
                name="leaguesId"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        League is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="League"
                placeholder="Select league"
                options={leagues}
              />

              <ProFormSelect
                name="stadiumId"
                width={"lg"}
                label="Stadium"
                placeholder="Select stadium"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Stadium is required! My friends
                      </p>
                    ),
                  },
                ]}
                options={stadiums}
              />

              <ProFormSelect
                name="teamHostId"
                width={"lg"}
                label="Host team"
                placeholder="Select host id"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Host team is required! My friends
                      </p>
                    ),
                  },
                ]}
                options={clubs}
              />

              <ProFormSelect
                name="teamGuestId"
                width={"lg"}
                label="Guest team"
                placeholder="Select guest id"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Guest team is required! My friends
                      </p>
                    ),
                  },
                ]}
                options={clubs}
              />

              <ProFormDateTimePicker
                name="startTime"
                showTime={{ format: "HH:mm" }}
                tooltip="This always set to Saudi Arabia Timezone"
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="Start time"
                placeholder="Choose start time"
                defaultValue={dayjs(matchData.startTime)
                  .tz("Asia/Kuwait")
                  .format("YYYY/MM/DD HH:mm")
                  .toString()}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Start time is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormDateTimePicker
                name="endTime"
                tooltip="This always set to Saudi Arabia Timezone"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="End time"
                placeholder="Choose end time"
              />

              <ProFormDateTimePicker
                name="openForTicket"
                tooltip="This always set to Saudi Arabia Timezone"
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                width={"lg"}
                label="Open sale date"
                placeholder="Choose open datetime for ticket sale"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Open for sale time is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormSelect
                name="status"
                width={"lg"}
                label="Status"
                placeholder="Select match status"
                onChange={(value) => setStatus(value)}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Match status is required! My friends
                      </p>
                    ),
                  },
                ]}
                options={MATCH_STATUSES}
              />

              {status === "END" && (
                <>
                  <ProFormDigit
                    name="scoredHost"
                    label="Host score"
                    width={"lg"}
                    placeholder="Enter host score"
                    min={0}
                    rules={[
                      {
                        required: true,
                        message: () => (
                          <p className="error-message">
                            Host score is required! My friends
                          </p>
                        ),
                      },
                    ]}
                  />

                  <ProFormDigit
                    name="scoredGuest"
                    width={"lg"}
                    label="Guest score"
                    placeholder="Enter guest score"
                    min={0}
                    rules={[
                      {
                        required: true,
                        message: () => (
                          <p className="error-message">
                            Guest score is required! My friends
                          </p>
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </div>

            <ProFormSwitch
              name="useCrowdHandler"
              label="Use crowd handler?"
              onChange={(value) => setUseCrowdHandler(value)}
              initialValue={useCrowdHandler}
            />

            {useCrowdHandler && (
              <ProFormText
                name="crowdhandlerLink"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Crowd handler link is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Crowd handler link"
                placeholder="Enter crowd handler link"
              />
            )}

            <div className="switch-button">
              <ProFormSwitch name="isFeatured" label="Is featured?" />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditMatch;
