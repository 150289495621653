import React, { useCallback, useEffect, useState } from "react";
import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormMoney,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useParams } from "react-router";
import {
  editTicketType,
  getClubsOfMatch,
  getGatesByStadium,
  getMatch,
  getTicketData,
} from "../../../api";
import { message } from "antd";
import { TICKET_TYPE_STATUSES } from "../../../utils/constant";

const EditTicketType = ({ ticketId, setIsEditTicket, setTicketTypes }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const { matchId } = useParams();

  const [clubs, setClubs] = useState([]);
  const [isClubSelected, setIsClubSelected] = useState(false);
  const [ticketData, setTicketData] = useState();
  const [gates, setGates] = useState(null);
  const [match, setMatch] = useState(null);
  const [customizeGateData, setCustomizeGateData] = useState(null);

  const handleSelectClub = (value) => {
    if (value === "choose") {
      return setIsClubSelected(false);
    }

    setIsClubSelected(true);
  };

  const fetchTicketTypes = useCallback(async () => {
    try {
      // const response = await getTicketTypes(matchId);
      const response = await getMatch(matchId);

      setTicketTypes(response?.ticketTypes);
    } catch (error) {
      console.log("Error", error);
    }
  }, [matchId, setTicketTypes]);

  const handleSubmit = useCallback(
    async (values) => {
      console.log("🚀 ~ values:", values);
      try {
        const response = await editTicketType(
          ticketData?.id,
          values?.clubId?.includes("choose") || !values?.clubId
            ? { ...values, clubId: null, netPrice: 0 }
            : values
        );
        if (response.status === 200) {
          setIsEditTicket(false);
          await fetchTicketTypes();
          messageApi.success("Create ticket type successfully! My friends");
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [fetchTicketTypes, messageApi, setIsEditTicket, ticketData?.id]
  );

  const fetchTicketData = useCallback(async () => {
    try {
      const response = await getTicketData(ticketId);

      const customizeGates = response?.gates.map((gate) => gate?.gateId);

      setCustomizeGateData(customizeGates);
      setTicketData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [ticketId]);

  const fetClubsOfMatch = useCallback(async () => {
    try {
      const response = await getClubsOfMatch(matchId);

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));

      setClubs([{ value: "choose", label: "Choose..." }, ...customizeData]);
    } catch (error) {
      console.log("Error", error);
    }
  }, [matchId]);

  const fetchMatch = useCallback(async () => {
    try {
      const response = await getMatch(matchId);

      setMatch(response);
    } catch (error) {
      console.log("error", error);
    }
  }, [matchId]);

  const fetchGatesByStadium = useCallback(async () => {
    try {
      const response = await getGatesByStadium(match?.stadiumId);

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));

      setGates(customizeData);
    } catch (err) {
      console.log("err", err);
    }
  }, [match?.stadiumId]);

  useEffect(() => {
    fetClubsOfMatch();
    fetchTicketData();
    fetchMatch();
    fetchGatesByStadium();
  }, [fetClubsOfMatch, fetchGatesByStadium, fetchMatch, fetchTicketData]);

  return (
    <PageContainer title="Edit Ticket Type">
      {contextHolder}
      {ticketData && (
        <ProForm
          name="editTicketType"
          layout="horizontal"
          initialValues={{ ...ticketData, gateIds: customizeGateData }}
          onFinish={handleSubmit}
          labelStyle={{
            width: "260px",
          }}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="Name in English"
                width={"md"}
                rules={[{ required: true }]}
              />

              <ProFormText
                name="nameAr"
                label="Name in Arabic"
                width={"md"}
                rules={[{ required: true }]}
              />

              <ProFormMoney
                name="price"
                label="Price"
                width={"md"}
                localeCustomSymbol={"SA"}
                min={0}
                customSymbol={"SAR"}
                rules={[{ required: true }]}
              />

              <ProFormSelect
                name="clubId"
                width={"md"}
                label="Club"
                placeholder="Choose club"
                options={clubs}
                onChange={handleSelectClub}
              />

              <ProFormSelect
                name="gateIds"
                mode="multiple"
                // multiple={true}
                width={"md"}
                label="Enter gates"
                placeholder="Choose allowed enter gates"
                options={gates}
              />

              {(isClubSelected || ticketData?.clubId) && (
                <ProFormMoney
                  name="netPrice"
                  label="Override price"
                  width={"md"}
                  localeCustomSymbol={"SA"}
                  min={0}
                  customSymbol={"SAR"}
                  rules={[{ required: true }]}
                />
              )}

              <ProFormDigit
                name="limitForUser"
                label="Limit buying tickets"
                width={"md"}
                min={1}
                rules={[{ required: true }]}
              />

              <ProFormDigit
                name="remainingTicketQuantity"
                label="Amount of tickets available"
                width={"md"}
                min={0}
                rules={[{ required: true }]}
              />

              <ProFormSelect
                name="ticketTypeStatus"
                width={"md"}
                label="Status"
                placeholder="Choose ticket type status"
                options={TICKET_TYPE_STATUSES}
              />
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditTicketType;
