export const USER_ROLES = [
  {
    value: "operator",
    label: "Operator",
  },
  {
    value: "user",
    label: "User",
  },
  {
    value: "gate-operator",
    label: "Gate Operator",
  },
];

export const GENDERS = [
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "FEMALE",
    label: "Female",
  },
];

export const STATUS_OPTIONS = [
  {
    label: "WAIT_FOR_CHECKIN",
    value: "WAIT_FOR_CHECKIN",
  },
  {
    label: "CHECKED_IN",
    value: "CHECKED_IN",
  },
  {
    label: "CANCELED",
    value: "CANCELED",
  },
  {
    label: "EXPIRED",
    value: "EXPIRED",
  },
];

export const PAYMENT_STATUS_OPTIONS = [
  {
    label: "WAITING_FOR_PAYMENT",
    value: "WAITING_FOR_PAYMENT",
  },
  {
    label: "SOLD",
    value: "SOLD",
  },
  {
    label: "EXPIRED",
    value: "EXPIRED",
  },
];

export const MATCH_STATUSES = [
  // {
  //   label: "SOLD_OUT",
  //   value: "SOLD_OUT",
  // },
  {
    label: "IN_PROGRESS",
    value: "IN_PROGRESS",
  },
  // {
  //   label: "OPEN_FOR_TICKET",
  //   value: "OPEN_FOR_TICKET",
  // },
  {
    label: "END",
    value: "END",
  },
];

export const PAYMENT_OPTIONS = [
  {
    label: "PAYTAP",
    value: "PAYTAP",
  },
  {
    label: "PAYFORT",
    value: "PAYFORT",
  },
];

export const TICKET_TYPE_STATUSES = [
  {
    label: "PUBLISHED",
    value: "PUBLISHED",
  },
  {
    label: "DRAFT",
    value: "DRAFT",
  },
];
