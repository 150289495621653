import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Avatar, Typography, Button, Modal } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import { deleteClub, getClubs } from "../../api";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import {
  DeleteFilled,
  FileTextFilled,
  EditFilled,
  ExclamationCircleFilled,
  UndoOutlined,
} from "@ant-design/icons";
import { SortingText, SortingDate } from "../../utils/sortingData";
import { filterDateTimeBetween, filterText } from "../../utils/filterData";

const { Text } = Typography;

const Clubs = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [filterClubs, setFilterClubs] = useState(data);

  const showModal = (record) => {
    setSelectedRecord(record);
    setIsShowModal(true);
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  const fetchClubs = useCallback(async () => {
    try {
      const response = await getClubs();

      setFilterClubs(response);
      setData(response);
    } catch (err) {
      console.log("err");
    }
  }, []);

  const handleSubmitModal = async () => {
    try {
      const response = selectedRecord?.deleted
        ? console.log("Restored record")
        : await deleteClub(selectedRecord?.id);

      if (response.status === 200) {
        await fetchClubs();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const newParams = {
      ...params,
      nameEn: params?.nameEn || "",
      nameAr: params?.nameAr || "",
      startTime: params?.createdAt?.[0],
      endTime: params?.createdAt?.[1],
    };

    const newFilterClubs = filterClubs.filter((club) => {
      const filterEnglishName = filterText(club?.nameEn, newParams?.nameEn);
      const filterArabicName = filterText(club?.nameAr, newParams?.nameAr);
      const filterCreatedAt = filterDateTimeBetween(
        club?.createdAt,
        newParams?.startTime,
        newParams?.endTime
      );

      return filterEnglishName && filterArabicName && filterCreatedAt;
    });

    setData(newFilterClubs);
  };

  const handleResetFilter = () => setData(filterClubs);

  useEffect(() => {
    fetchClubs();
  }, [fetchClubs]);

  const tableColumns = [
    {
      key: "logo",
      title: "Logo",
      dataIndex: "logo",
      search: false,
      render: (_, record) => {
        return <Avatar src={record?.logo} />;
      },
    },
    {
      key: "nameEn",
      title: "English Name",
      dataIndex: "nameEn",
      search: false,
      sorter: (current, next) => SortingText(current.nameEn, next.nameEn),
    },
    {
      key: "nameAr",
      title: "Arabic Name",
      dataIndex: "nameAr",
      sorter: (current, next) => SortingText(current.nameAr, next.nameAr),
    },
    {
      key: "createdAt",
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      key: "updatedAt",
      title: "Updated At",
      dataIndex: "updatedAt",
      hideInTable: true,
      search: false,
      sorter: (current, next) => SortingDate(current.updatedAt, next.updatedAt),
      render: (_, record) => {
        return <Text>{dayjs(record.updatedAt).fromNow()}</Text>;
      },
    },
    {
      key: "actions",
      title: "Actions",
      search: false,
      width: 100,
      render: (_, record) => [
        <div key={record.id} className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/clubs/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/clubs/${record?.id}/edit`)}
          />

          {record?.deleted ? (
            <Button
              className="icon-button"
              icon={<UndoOutlined />}
              onClick={() => showModal(record)}
            />
          ) : (
            <Button
              className="icon-button"
              icon={<DeleteFilled />}
              onClick={() => showModal(record)}
            />
          )}
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Clubs">
      <Modal
        open={isShowModal}
        onOk={() => handleSubmitModal()}
        onCancel={handleCancel}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          {selectedRecord?.deleted ? (
            <p>Are you sure to restore this record?</p>
          ) : (
            <p>Are you sure to delete this record?</p>
          )}
        </div>
      </Modal>

      <ProTable
        rowKey="id"
        request={({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);

          return {
            data,
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 1400 }}
        columns={tableColumns}
        dataSource={data}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/clubs/new");
            }}
            type="primary"
          >
            New Club
          </Button>,
        ]}
        search={{
          labelWidth: "auto",
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Clubs;
