import { useCallback, useEffect, useState } from "react";
import { getTicketData } from "../../../api";
import { PageContainer, ProDescriptions } from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Card, Divider, Image } from "antd";

const TicketTypeDetails = ({ ticketId }) => {
  const [ticketData, setTicketData] = useState();

  const fetchTicketData = useCallback(async () => {
    try {
      const response = await getTicketData(ticketId);

      setTicketData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [ticketId]);

  useEffect(() => {
    fetchTicketData();
  }, [fetchTicketData]);

  return (
    <>
      {ticketData && (
        <PageContainer className="ticket-draw-container">
          <Card title="Ticket Type Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Ticket ID"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="English name" valueType="text">
                {ticketData?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Arabic name" valueType="text">
                {ticketData?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Gates" valueType="text">
                {ticketData?.gates.length > 0
                  ? ticketData?.gates.map(
                      ({ gate }) => gate.nameEn + " - " + gate.nameAr
                    )
                  : "-"}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Status" valueType="text">
                {ticketData?.ticketTypeStatus}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(ticketData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Ticket Price">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Price"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.price}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Override price"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.netPrice}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Limit buying tickets"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.limitForUser}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Remaining tickets"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.remainingTicketQuantity}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="Club Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Club ID"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.club?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="English name"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.club?.nameEn}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Arabic name"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.club?.nameAr}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Slug"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {ticketData?.club?.slug}
              </ProDescriptions.Item>

              <ProDescriptions.Item
                label="Created at"
                valueType="text"
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {dayjs(ticketData?.club?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Logo">
                <Image width={150} src={ticketData?.club?.logo} />
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default TicketTypeDetails;
