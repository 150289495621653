import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormDatePicker,
  ProFormTextArea,
} from "@ant-design/pro-components";
import { getLeagueData, patchLeague } from "../../../api";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EditLeague = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [logo, setLogo] = useState();
  const [leagueData, setLeagueData] = useState();

  const fetchLeagueData = useCallback(async () => {
    try {
      const response = await getLeagueData(id);

      setLeagueData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = { ...values, image: logo };

      const response = await patchLeague(id, newParams);

      if (response.status === 200) {
        navigate("/leagues");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchLeagueData();
  }, [fetchLeagueData]);

  return (
    <PageContainer title="Edit league">
      {leagueData && (
        <ProForm
          name="editLeagueForm"
          layout="horizontal"
          initialValues={leagueData}
          onFinish={handleSubmit}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="English Name"
                width={"lg"}
                placeholder="Enter league name in English"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="nameAr"
                width={"lg"}
                label="Arabic Name"
                placeholder="Enter league name in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormTextArea
                name="descriptionEn"
                width="lg"
                label="English description"
                placeholder="Enter description in English"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English description is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormTextArea
                name="descriptionAr"
                width="lg"
                label="Arabic description"
                placeholder="Enter description in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic description is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormDatePicker
                name="startTime"
                width="lg"
                label="Start time"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Start time is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormDatePicker
                name="endTime"
                width="lg"
                label="End time"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        End time is required! My friends
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          </ProForm.Group>

          <ProForm.Group>
            <ImageUpload
              callBackGetUrl={setLogo}
              initialValue={leagueData?.image}
            />
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditLeague;
