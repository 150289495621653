import {
  ProForm,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import { getStadiumData, patchStadium } from "../../../api";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const EditStadium = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [logo, setLogo] = useState();
  const [stadiumData, setStadiumData] = useState();

  const fetchStadiumData = useCallback(async () => {
    try {
      const response = await getStadiumData(id);

      setStadiumData(response);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const newParams = { ...values, logo };

      const response = await patchStadium(id, newParams);

      if (response.status === 200) {
        navigate("/stadiums");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  useEffect(() => {
    fetchStadiumData();
  }, [fetchStadiumData]);

  return (
    <PageContainer title="Edit stadium">
      {stadiumData && (
        <ProForm
          name="editStadiumForm"
          layout="horizontal"
          initialValues={stadiumData}
          onFinish={handleSubmit}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormText
                name="nameEn"
                label="English Name"
                width={"lg"}
                placeholder="Enter stadium name in English"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        English name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />

              <ProFormText
                name="nameAr"
                width={"lg"}
                label="Arabic Name"
                placeholder="Enter stadium name in Arabic"
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Arabic name is required! My friends
                      </p>
                    ),
                  },
                ]}
              />
            </div>
          </ProForm.Group>

          <ProForm.Group>
            <ImageUpload
              callBackGetUrl={setLogo}
              initialValue={stadiumData?.logo}
            />
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default EditStadium;
