import React, { useState } from "react";
import {
  PageContainer,
  ProCard,
  ProConfigProvider,
  ProLayout,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import menuData from "./menuData";
import { Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

const useLayout = (Component) => {
  const navigate = useNavigate();

  const [isShowModal, setIsShowModal] = useState(false);

  const handleCancel = () => setIsShowModal(false);

  const handleSubmitModal = () => {
    localStorage.removeItem("token");
    navigate("/login");

    handleCancel();
  };

  return (props) => {
    return (
      <div
        style={{
          height: "100vh",
        }}
      >
        <Modal
          open={isShowModal}
          onOk={() => handleSubmitModal()}
          onCancel={handleCancel}
          centered
        >
          <div className="delete-content">
            <ExclamationCircleFilled style={{ fontSize: "48px" }} />
            <p>Are you sure to logout?</p>
          </div>
        </Modal>

        <ProConfigProvider hashed={false}>
          <ProLayout
            title=""
            layout="side"
            menuItemRender={(item, dom) => {
              return (
                <div
                  onClick={() => {
                    if (item.path === "/logout") {
                      setIsShowModal(true);
                    } else {
                      navigate(item.path);
                    }
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  {dom}
                </div>
              );
            }}
            // logo={`${process.env.REACT_APP_LOGO_URL}/public/new_logo.png`}
            logo={`../new_logo.png`}
            {...menuData}
          >
            <PageContainer>
              <ProCard
                style={{
                  height: "fit-content",
                  minHeight: 800,
                }}
              >
                <Component {...props} />
              </ProCard>
            </PageContainer>
          </ProLayout>
        </ProConfigProvider>
      </div>
    );
  };
};

export default useLayout;
