import {
  ProForm,
  ProFormDatePicker,
  ProFormSelect,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { postUser } from "../../../api";
import { GENDERS, USER_ROLES } from "../../../utils/constant";

const NewUser = () => {
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      const newParams = { ...values, isArgreeToTerm: true };

      const response = await postUser(newParams);

      if (response.status === 201) {
        navigate("/users");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <PageContainer title="Create new user">
      <ProForm
        name="createUserForm"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="fullName"
              label="Full name"
              width={"lg"}
              placeholder="Enter full name"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Full name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormSelect
              name="gender"
              width={"lg"}
              label="Gender"
              placeholder="Enter gender"
              options={GENDERS}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Gender is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="email"
              width={"lg"}
              label="Email"
              placeholder="Enter email"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Email is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="phoneNumber"
              width={"lg"}
              label="Phone"
              placeholder="Enter phone"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Phone number is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormSelect
              name="roles"
              width={"lg"}
              label="Role"
              placeholder="Enter role"
              options={USER_ROLES}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Role is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormDatePicker
              name="dateOfBirth"
              width={"lg"}
              label="Date of birth"
              placeholder="Enter birth"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Date of birth is required! My friends
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewUser;
