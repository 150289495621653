import { LockOutlined, MobileOutlined } from "@ant-design/icons";
import {
  LoginForm,
  ProFormCaptcha,
  ProFormText,
  ProConfigProvider,
} from "@ant-design/pro-components";
import { message } from "antd";
import { requestOTP, verifyOTP } from "../../api";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  return (
    <ProConfigProvider hashed={false}>
      <div style={{ backgroundColor: "white" }}>
        <LoginForm
          onFinish={async ({ phone, captcha }) => {
            try {
              const response = await verifyOTP(phone, captcha);
              localStorage.setItem("token", response.accessToken);

              message.success("Login successfully, welcome aboard!");

              navigate("/");
            } catch (error) {
              console.log("error", error);
              message.error(error.error);
            }
          }}
          logo="logo.png"
          subTitle="Locate Booking Secret Base"
          submitter={{
            searchConfig: { submitText: "Login" },
          }}
          autoFocusFirstInput
        >
          <>
            <ProFormText
              fieldProps={{
                size: "large",
                prefix: <MobileOutlined className={"prefixIcon"} />,
              }}
              name="phone"
              placeholder={"Phone number"}
              rules={[
                {
                  required: true,
                  message: "Phone number is required!",
                },
              ]}
            />
            <ProFormCaptcha
              fieldProps={{
                size: "large",
                prefix: <LockOutlined className={"prefixIcon"} />,
              }}
              captchaProps={{
                size: "large",
              }}
              placeholder={"OTP"}
              captchaTextRender={(timing, count) => {
                if (timing) {
                  return `${count} ${"seconds"}`;
                }
                return "Get OTP";
              }}
              name="captcha"
              phoneName={"phone"}
              rules={[
                {
                  required: true,
                  message: "OTP is required!",
                },
              ]}
              onGetCaptcha={async (phone) => {
                try {
                  const response = await requestOTP(phone);

                  if (response) {
                    if (process.env.NODE_ENV === "development") {
                      message.success(`Your OTP is：${response.payload}`);
                      return;
                    }

                    message.success(
                      "OTP had sent to your phone, please check!"
                    );
                  }
                } catch (error) {
                  console.log("Error OTP:", error);
                }
              }}
            />
          </>
        </LoginForm>
      </div>
    </ProConfigProvider>
  );
};
export default Login;
