import {
  ProForm,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormDateTimePicker,
  PageContainer,
} from "@ant-design/pro-components";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { getClubs, getLeagues, postMatches, getStadiums } from "../../../api";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const NewMatch = () => {
  const navigate = useNavigate();

  const [clubs, setClubs] = useState([]);
  const [leagues, setLeagues] = useState([]);
  const [stadiums, setStadiums] = useState([]);
  const [useCrowdHandler, setUseCrowdHandler] = useState(true);

  const handleSubmit = async (values) => {
    console.log("🚀 ~ values:", values);
    try {
      const response = await postMatches({
        ...values,
        startTime: values.startTime
          ? dayjs
              .tz(values.startTime, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        endTime: values.endTime
          ? dayjs
              .tz(values.endTime, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        openForTicket: values.openForTicket
          ? dayjs
              .tz(values.openForTicket, "YYYY-MM-DD HH:mm", "Asia/Riyadh")
              .utc()
              .format()
          : null,
        isFeatured: values.isFeatured || false,
      });

      if (response.status === 201 || response.status === 200) {
        navigate("/matches");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const fetchClubs = useCallback(async () => {
    try {
      const response = await getClubs();

      const customizeData = response.map((item) => ({
        value: item.id,
        label: item.nameEn,
      }));

      setClubs(customizeData);
    } catch (err) {
      console.log("err");
    }
  }, []);

  const fetchLeagues = useCallback(async () => {
    try {
      const response = await getLeagues();

      const customizeData = response.map((item) => ({
        value: item.id,
        label: item.nameEn,
      }));

      setLeagues(customizeData);
    } catch (err) {
      console.log("err");
    }
  }, []);

  const fetchStadiums = useCallback(async () => {
    try {
      const response = await getStadiums();

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));
      setStadiums(customizeData);
    } catch (err) {
      console.log("err");
    }
  }, []);

  useEffect(() => {
    fetchClubs();
    fetchLeagues();
    fetchStadiums();
  }, [fetchClubs, fetchLeagues, fetchStadiums]);

  return (
    <PageContainer title="Create new match" className="match-wrapper">
      <ProForm
        name="createMatchForm"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ProForm.Group direction="vertical">
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="English Name"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English name is required! My friends
                    </p>
                  ),
                },
              ]}
              placeholder="Enter match name in English"
            />

            <ProFormText
              name="nameAr"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic name is required! My friends
                    </p>
                  ),
                },
              ]}
              label="Arabic Name"
              placeholder="Enter match name in Arabic"
            />

            <ProFormSelect
              name="leaguesId"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      League is required! My friends
                    </p>
                  ),
                },
              ]}
              label="League"
              placeholder="Select league"
              options={leagues}
            />

            <ProFormSelect
              name="stadiumId"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Stadium is required! My friends
                    </p>
                  ),
                },
              ]}
              label="Stadium"
              placeholder="Select stadium"
              options={stadiums}
            />

            <ProFormSelect
              name="teamHostId"
              width={"lg"}
              label="Host team"
              placeholder="Select host id"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Host team is required! My friends
                    </p>
                  ),
                },
              ]}
              options={clubs}
            />

            <ProFormSelect
              name="teamGuestId"
              width={"lg"}
              label="Guest team"
              placeholder="Select guest id"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Guest team is required! My friends
                    </p>
                  ),
                },
              ]}
              options={clubs}
            />

            <ProFormDateTimePicker
              name="startTime"
              showTime={{ format: "HH:mm" }}
              tooltip="This always set to Saudi Arabia Timezone"
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="Start time"
              placeholder="Choose start time"
              onOk={(value) => {
                console.log("value", value);
              }}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Start time is required! My friends
                    </p>
                  ),
                },
              ]}
              options={clubs}
            />

            <ProFormDateTimePicker
              name="endTime"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="End time"
              placeholder="Choose end time"
              options={clubs}
            />

            <ProFormDateTimePicker
              name="openForTicket"
              tooltip="This always set to Saudi Arabia Timezone"
              showTime={{ format: "HH:mm" }}
              format="YYYY-MM-DD HH:mm"
              width={"lg"}
              label="Open sale date"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Open for sale is required! My friends
                    </p>
                  ),
                },
              ]}
              placeholder="Choose open datetime for ticket sale"
            />
          </div>

          <ProFormSwitch
            name="useCrowdHandler"
            label="Use crowd handler?"
            onChange={(value) => setUseCrowdHandler(value)}
            initialValue={useCrowdHandler}
          />

          {useCrowdHandler && (
            <ProFormText
              name="crowdhandlerLink"
              width={"lg"}
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Crowd handler link is required! My friends
                    </p>
                  ),
                },
              ]}
              label="Crowd handler link"
              placeholder="Enter crowd handler link"
            />
          )}

          <div className="switch-button">
            <ProFormSwitch name="isFeatured" label="Is featured?" />
          </div>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewMatch;
