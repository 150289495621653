import { PlusOutlined, TableOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Typography, Button, Modal } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import { useNavigate } from "react-router";
import { deleteMatch, exportExcel, getMatches } from "../../api";
import {
  DeleteFilled,
  FileTextFilled,
  EditFilled,
  UndoOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as timezone from "dayjs/plugin/timezone";
import { SortingDate, SortingText } from "../../utils/sortingData";
import { filterDateTimeBetween, filterText } from "../../utils/filterData";

const { Text } = Typography;

dayjs.extend(utc);
dayjs.extend(timezone);

const Matches = () => {
  const navigate = useNavigate();

  const [matches, setMatches] = useState([]);
  const [filterMatches, setFilterMatches] = useState(matches);
  const [selectedRecord, setSelectedRecord] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [columnsStateMap, setColumnsStateMap] = useState({
    nameEn: {
      show: false,
      order: 0,
    },
    endTime: {
      show: false,
      order: 0,
    },
  });

  const showModal = (record, modalType) => {
    setSelectedRecord(record);
    setIsShowModal(true);
    setModalType(modalType);
  };

  const handleCancel = () => {
    setIsShowModal(false);
  };

  const fetchMatches = useCallback(async () => {
    try {
      const response = await getMatches();

      setMatches(response);
      setFilterMatches(response);
    } catch (err) {
      console.log("err");
    }
  }, []);

  const handleSubmitModal = async () => {
    try {
      const response = selectedRecord?.deleted
        ? console.log("Restored record")
        : await deleteMatch(selectedRecord?.id);

      if (response.status === 200) {
        await fetchMatches();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      handleCancel();
    }
  };

  const handleSubmitFilter = (params) => {
    const newParams = {
      ...params,
      nameEn: params?.nameEn || "",
      nameAr: params?.nameAr || "",
      matchStartTimeFrom: params?.startTime?.[0],
      matchStartTimeTo: params?.startTime?.[0],
      matchEndTimeFrom: params?.endTime?.[0],
      matchEndTimeTo: params?.endTime?.[1],
      openForTicketStartTime: params?.openForTicket?.[0],
      openForTicketEndTime: params?.openForTicket?.[1],
      createdAtFrom: params?.createdAt?.[0],
      createdAtTo: params?.createdAt?.[1],
      isFeatured: params?.isFeatured,
    };

    const newFilterMatches = filterMatches.filter((match) => {
      const filterEnglishName = filterText(match?.nameEn, newParams?.nameEn);
      const filterArabicName = filterText(match?.nameAr, newParams?.nameAr);
      const filterIsFeatured = filterText(
        match?.isFeatured,
        newParams?.isFeatured
      );
      const filterStartTime = filterDateTimeBetween(
        match?.startTime,
        newParams?.matchStartTimeFrom,
        newParams?.matchStartTimeTo
      );

      const filterEndTime = filterDateTimeBetween(
        match?.startTime,
        newParams?.matchEndTimeFrom,
        newParams?.matchEndTimeTo
      );

      const filterOpenForTicket = filterDateTimeBetween(
        match?.openForTicket,
        newParams?.openForTicketStartTime,
        newParams?.openForTicketEndTime
      );

      const filterCreatedAt = filterDateTimeBetween(
        match?.createdAt,
        newParams?.createdAtFrom,
        newParams?.createdAtTo
      );

      return (
        filterEnglishName &&
        filterArabicName &&
        filterStartTime &&
        filterEndTime &&
        filterOpenForTicket &&
        filterIsFeatured &&
        filterCreatedAt
      );
    });

    setMatches(newFilterMatches);
  };

  const handleResetFilter = () => setMatches(filterMatches);

  useEffect(() => {
    fetchMatches();
  }, [fetchMatches]);

  const onExportToExcel = useCallback(async () => {
    setIsShowModal(false);
    const matchId = selectedRecord?.id;

    try {
      const response = await exportExcel(matchId, 1, 10);
      const blob = response.data;
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "clubTable.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.log("Export Excel Fail With Error---->", error);
    }
  }, [selectedRecord?.id]);

  const tableColumns = [
    {
      title: "English Name",
      dataIndex: "nameEn",
      search: false,
      sorter: (current, next) => SortingText(current.nameEn, next.nameEn),
    },
    {
      title: "Arabic Name",
      dataIndex: "nameAr",
      sorter: (current, next) => SortingText(current.nameAr, next.nameAr),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      valueType: "dateTimeRange",
      search: false,
      defaultSortOrder: "descend",
      sorter: (current, next) =>
        SortingDate(current?.startTime, next?.startTime),
      render: (_, record) => {
        return (
          <Text>
            {dayjs
              .utc(record.startTime)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm")}
          </Text>
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      valueType: "dateTimeRange",
      search: false,
      sorter: (current, next) => SortingDate(current?.endTime, next?.endTime),
      render: (_, record) => {
        if (record.endTime) {
          return (
            <Text>
              {dayjs
                .utc(record.endTime)
                .utcOffset(3)
                .format("YYYY/MM/DD HH:mm")}
            </Text>
          );
        }

        return <Text centered>-</Text>;
      },
    },
    {
      title: "Open Sale Time",
      dataIndex: "openForTicket",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) =>
        SortingDate(current?.openForTicket, next?.openForTicket),
      render: (_, record) => {
        return (
          <Text>
            {dayjs
              .utc(record.openForTicket)
              .utcOffset(3)
              .format("YYYY/MM/DD HH:mm")}
          </Text>
        );
      },
    },
    {
      title: "Is featured",
      dataIndex: "isFeatured",
      valueEnum: {
        TRUE: {
          text: "TRUE",
        },
        FALSE: {
          text: "FALSE",
        },
      },
      render: (_, record) => {
        if (record.isFeatured) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      title: "Use crowd handler",
      dataIndex: "useCrowdHandler",
      search: false,
      render: (_, record) => {
        if (record?.useCrowdHandler) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      title: "Crowd handler link",
      dataIndex: "crowdhandlerLink",
      search: false,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) =>
        SortingDate(current?.createdAt, next?.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record?.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      title: "Actions",
      width: 100,
      key: "option",
      valueType: "option",
      render: (_, record) => [
        <div className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/matches/${record?.id}`)}
          />

          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/matches/${record?.id}/edit`)}
          />

          {record?.deleted ? (
            <Button
              className="icon-button"
              icon={<UndoOutlined />}
              onClick={() => showModal(record)}
            />
          ) : (
            <Button
              className="icon-button"
              icon={<DeleteFilled />}
              onClick={() => showModal(record)}
            />
          )}

          <Button
            className="icon-button"
            icon={<TableOutlined />}
            onClick={() => showModal(record, "excel")}
          />
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Matches">
      <Modal
        open={isShowModal}
        onOk={() => handleSubmitModal()}
        onCancel={handleCancel}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          {selectedRecord?.deleted ? (
            <p>Are you sure to restore this record?</p>
          ) : (
            <p>Are you sure to delete this record?</p>
          )}
        </div>
      </Modal>

      <Modal
        open={modalType === "excel" && isShowModal}
        onOk={onExportToExcel}
        onCancel={handleCancel}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          <p>Do you want to export this match data to excel?</p>
        </div>
      </Modal>

      <ProTable
        rowKey="id"
        columns={tableColumns}
        dataSource={matches}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 1800 }}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/matches/new");
            }}
            type="primary"
          >
            New Matches
          </Button>,
        ]}
        search={{
          labelWidth: "auto",
        }}
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Matches;
