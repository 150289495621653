import dayjs from "dayjs";

export const SortingText = (currentValue, nextValue) => {
  if (currentValue.toLowerCase() < nextValue.toLowerCase()) return -1;

  if (currentValue.toLowerCase() > nextValue.toLowerCase()) return 1;

  return 0;
};

export const SortingDate = (currentValue, nextValue) => {
  if (dayjs(currentValue).isBefore(dayjs(nextValue))) return 1;
  if (dayjs(currentValue).isAfter(dayjs(nextValue))) return -1;

  return 0;
};

export const SortingNumber = (currentValue, nextValue) => {
  return currentValue - nextValue;
};
