import {
  ProForm,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import { postClub } from "../../../api";
import { useNavigate } from "react-router";
import ImageUpload from "../../../components/UploadImage";
import { useState } from "react";

const NewClub = () => {
  const navigate = useNavigate();

  const [logo, setLogo] = useState();

  const handleSubmit = async (values) => {
    try {
      const newParams = { ...values, logo };

      const response = await postClub(newParams);

      if (response.status === 201) {
        navigate("/clubs");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <PageContainer title="Create new club">
      <ProForm
        name="createClubForm"
        layout="horizontal"
        onFinish={handleSubmit}
      >
        <ProForm.Group className="heheh">
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="English Name"
              width={"lg"}
              placeholder="Enter club name in English"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      English name is required! My friends
                    </p>
                  ),
                },
              ]}
            />

            <ProFormText
              name="nameAr"
              width={"lg"}
              label="Arabic Name"
              placeholder="Enter club name in Arabic"
              rules={[
                {
                  required: true,
                  message: () => (
                    <p className="error-message">
                      Arabic name is required! My friends
                    </p>
                  ),
                },
              ]}
            />
          </div>
        </ProForm.Group>

        <ProForm.Group>
          <ImageUpload callBackGetUrl={setLogo} />
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewClub;
