import React, { useCallback, useEffect, useState } from "react";
import {
  getOdooData,
  getSettings,
  patchSettings,
  postOdooData,
} from "../../api";
import {
  PageContainer,
  ProForm,
  ProFormDigit,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
} from "@ant-design/pro-components";
import { PAYMENT_OPTIONS } from "../../utils/constant";
import { Button, Typography, notification } from "antd";

const { Text } = Typography;

const Settings = () => {
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log("🚀 ~ formData:", formData);

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement, type) => {
    switch (type) {
      case 1:
        api.error({
          message: "Update settings",
          description: "Something went wrong!",
          placement,
          duration: 3,
        });
        break;

      default:
        api.success({
          message: "Update settings",
          description: "Update settings successfully.",
          placement,
          duration: 3,
        });
        break;
    }
  };

  const fetchSettings = useCallback(async () => {
    try {
      const response = await getSettings();

      response.forEach((element) => {
        if (element?.key === "ENABLE_SOCKET") {
          return setFormData((prevState) => ({
            ...prevState,
            enableSocket: element?.content?.status,
          }));
        }

        if (element?.key === "PAYMENT") {
          return setFormData((prevState) => ({
            ...prevState,
            payment: element?.content?.payment_option,
          }));
        }

        if (element?.key === "ODOO_AUTHENTICATE") {
          return setFormData((prevState) => ({
            ...prevState,
            dbName: element?.content?.db,
            dbUser: element?.content?.login,
            dbPassword: element?.content?.password,
          }));
        }

        return setFormData((prevState) => ({
          ...prevState,
          timeoutOrder: element?.content?.limit,
        }));
      });
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const fetchOdooData = useCallback(async () => {
    try {
      const response = await getOdooData();

      setFormData((prevState) => ({
        ...prevState,
        order: response?.order,
        ticket: response?.ticket,
      }));
    } catch (error) {
      console.log("Error", error);
    }
  }, []);

  const handleSubmit = async (values) => {
    let response = null;
    try {
      const customizeParams = [
        {
          key: "ENABLE_SOCKET",
          content: {
            status: values?.enableSocket,
          },
        },
        {
          key: "PAYMENT",
          content: {
            payment_option: values?.payment,
          },
        },
        {
          key: "TIMEOUT_ORDER",
          content: {
            limit: values?.timeoutOrder || false,
          },
        },
        {
          key: "ODOO_AUTHENTICATE",
          content: {
            db: values?.dbName,
            login: values?.dbUser,
            password: values.dbPassword,
          },
        },
      ];

      response = await patchSettings(customizeParams);
      console.log("🚀 ~ response:", response);

      if (response?.status === 200) {
        openNotification("bottomLeft");
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      if (!response) {
        openNotification("bottomLeft", 1);
      }
    }
  };

  const handleSyncDataToOdoo = async () => {
    try {
      setIsLoading(true);
      await postOdooData();
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSettings();
    fetchOdooData();
  }, [fetchOdooData, fetchSettings]);

  return (
    <PageContainer title="Settings">
      {contextHolder}

      {formData && (
        <ProForm
          name="settingsForm"
          layout="horizontal"
          onFinish={handleSubmit}
          initialValues={formData}
        >
          <ProForm.Group>
            <div className="form-fields-wrapper">
              <ProFormDigit
                name="timeoutOrder"
                width={"lg"}
                min={0}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Timeout order is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Timeout for order"
                placeholder="Enter timeout"
              />

              <ProFormSelect
                name="payment"
                width={"lg"}
                rules={[
                  {
                    required: true,
                    message: () => (
                      <p className="error-message">
                        Payment is required! My friends
                      </p>
                    ),
                  },
                ]}
                label="Payment"
                placeholder="Select payment"
                options={PAYMENT_OPTIONS}
              />

              <ProFormText
                name="dbName"
                width={"lg"}
                min={0}
                label="Odoo DB Name"
                placeholder="Example: booking-locate"
              />

              <ProFormText
                name="dbUser"
                width={"lg"}
                min={0}
                label="Odoo DB User"
                placeholder="Ex: api@locate.sa"
              />

              <ProFormText
                name="dbPassword"
                width={"lg"}
                min={0}
                label="Odoo DB Password"
                placeholder="Example: 112233"
              />

              <div className="switch-button enable-switch">
                <ProFormSwitch name="enableSocket" label="Enable socket?" />
              </div>

              <div className="settings-sync-Odoo">
                <ProFormText width={"lg"}>
                  Orders: {formData.order} / Tickets: {formData.ticket} not seen
                  to Odoo yet
                </ProFormText>

                <Button
                  type="primary"
                  onClick={handleSyncDataToOdoo}
                  loading={isLoading}
                >
                  Sync to Odoo
                </Button>
              </div>
            </div>
          </ProForm.Group>
        </ProForm>
      )}
    </PageContainer>
  );
};

export default Settings;
