import { message } from "antd";
import api from "../utils/axios-client";

console.log("process.env", process.env);

export const requestOTP = async (phone) => {
  try {
    const response = await api.post("operator/sms-otps", {
      recipients: phone,
    });

    return response.data;
  } catch (error) {
    const {
      response: {
        data: { message: text },
      },
    } = error;

    message.error(text);
    console.log("🚀 ~ error:", error);
  }
};

export const verifyOTP = async (phone, code) => {
  try {
    const response = await api.post("operator/sms-otps/verify", {
      recipients: phone,
      code: Number(code),
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

// Clubs
export const getClubs = async () => {
  try {
    const response = await api.get("operator/clubs");

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getGatesByStadium = async (stadiumId) => {
  try {
    const response = await api.get(`operator/stadiums/${stadiumId}/gates`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const postClub = async (params) => {
  try {
    const response = await api.post("operator/clubs", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const patchClub = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/clubs/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteClub = async (recordId) => {
  try {
    const response = await api.delete(`operator/clubs/${recordId}`);

    return response;
  } catch (error) {
    console.log("error");
  }
};

export const getClubData = async (recordId) => {
  try {
    const response = await api.get(`operator/clubs/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const exportExcel = async (matchId, page, limit) => {
  return await api.post(
    `operator/matches/${matchId}/tickets/excel?page=${page}&limit=${limit}`,
    {},
    {
      responseType: "blob",
    }
  );
};

// Leagues
export const getLeagues = async () => {
  try {
    const {
      data: { data: response },
    } = await api.get("operator/leagues");

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const postLeague = async (params) => {
  try {
    const response = await api.post("operator/leagues", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const patchLeague = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/leagues/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteLeague = async (recordId) => {
  try {
    const response = await api.delete(`operator/leagues/${recordId}`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getLeagueData = async (recordId) => {
  try {
    const response = await api.get(`operator/leagues/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

// Stadiums
export const getStadiums = async () => {
  try {
    const {
      data: { data: response },
    } = await api.get("operator/stadiums");

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const postStadium = async (params) => {
  try {
    const response = await api.post("operator/stadiums", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const patchStadium = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/stadiums/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteStadium = async (recordId) => {
  try {
    const response = await api.delete(`operator/stadiums/${recordId}`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getStadiumData = async (recordId) => {
  try {
    const response = await api.get(`operator/stadiums/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const getGateData = async (stadiumId) => {
  try {
    const response = await api.get(`operator/stadiums/${stadiumId}/gates`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const postGate = async (stadiumId, params) => {
  try {
    const response = await api.post(
      `operator/stadiums/${stadiumId}/gates`,
      params
    );

    return response;
  } catch (error) {
    console.log("error");
  }
};

// Matches
export const getMatches = async () => {
  try {
    const response = await api.get("operator/matches");

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postMatches = async (params) => {
  try {
    const response = await api.post("matches", params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const patchMatch = async (recordId, params) => {
  try {
    const response = await api.patch(`matches/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteMatch = async (recordId) => {
  try {
    const response = await api.delete(`matches/${recordId}`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

// Users
export const getUsers = async (page, limit, filterParams) => {
  try {
    const response = await api.get("operator/users", {
      params: {
        ...filterParams,
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postUser = async (params) => {
  try {
    const response = await api.post("operator/users", params);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const patchUser = async (recordId, params) => {
  try {
    const response = await api.patch(`operator/users/${recordId}`, params);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const deleteUser = async (recordId) => {
  try {
    const response = await api.delete(`operator/users/${recordId}`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getUserData = async (recordId) => {
  try {
    const response = await api.get(`operator/users/${recordId}`);

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

export const exportUsers = async () => {
  try {
    return await api.post(
      `operator/users/excel`,
      {},
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    console.log("Error", error);
  }
};

// Upload images
export const uploadImageAPi = async (file) => {
  try {
    const response = await api.post("operator/users/upload", file, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    return response.data;
  } catch (error) {
    console.log("error");
  }
};

// Get Match Detail
export const getMatch = async (matchId) => {
  try {
    const response = await api.get(`operator/matches/${matchId}`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// Create ticket type
export const getTicketTypes = async (matchId) => {
  try {
    const response = await api.get(`operator/matches/${matchId}/ticket-types`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createTicketType = async (matchId, data) => {
  try {
    const response = await api.post(`matches/${matchId}/ticket-types`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const editTicketType = async (ticketId, data) => {
  try {
    const response = await api.patch(`operator/ticket-types/${ticketId}`, data);

    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteTicketType = async (recordId) => {
  try {
    const response = await api.delete(`operator/ticket-types/${recordId}`);

    return response;
  } catch (error) {
    console.log("error", error);
  }
};

export const getTicketData = async (ticketId) => {
  try {
    const response = await api.get(`/ticket-types/${ticketId}`);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTickets = async (matchId, page, limit, filterParams) => {
  try {
    const response = await api.get(`operator/matches/${matchId}/tickets`, {
      params: {
        ...filterParams,
        // status: JSON.stringify(filterParams.status),
        // paymentStatus: JSON.stringify(filterParams.paymentStatus),
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getClubTickets = async (matchId, page, limit, filterParams) => {
  try {
    const response = await api.get(`operator/ticket-free/matches/${matchId}`, {
      headers: {
        "x-token-auth": "U2FsdGVkX18oekpRDKTJdYxwqOF2b8ceB2F4Fm1Zdxw=",
      },
      params: {
        ...filterParams,
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

// Get Clubs in the match
export const getClubsOfMatch = async (matchId) => {
  try {
    const response = await api.get(`operator/matches/${matchId}/clubs`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// Get OTPs
export const getOTPs = async (page, limit, filterParams) => {
  try {
    const response = await api.get(`operator/sms-otps`, {
      params: {
        ...filterParams,
        page,
        limit,
      },
    });

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getTicketCheckedInHistories = async (
  ticketId,
  page,
  limit,
  filterParams
) => {
  try {
    const response = await api.get(
      `operator/tickets/${ticketId}/check-in-histories`,
      {
        params: {
          ...filterParams,
          page,
          limit,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getClubTicketCheckedInHistories = async (
  ticketId,
  page,
  limit,
  filterParams
) => {
  try {
    const response = await api.get(
      `operator/tickets-free/${ticketId}/check-in-histories`,
      {
        params: {
          ...filterParams,
          page,
          limit,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// Check in
export const postCheckInStatus = async (params, isClubTicket = false) => {
  try {
    const encryptedCheckInCode = params.encryptedCheckInCode;
    const response = await api.post(
      `operator/check-in`,
      {
        ...params,
        encryptedCheckInCode: isClubTicket
          ? `giftTicket.${encryptedCheckInCode}`
          : encryptedCheckInCode,
      },
      {
        headers: {
          "x-type-key": "U2FsdGVkX18oekpRDKTJdYxwqOF2b8ceB2F4Fm1Zdxw=",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// Sync data to Odoo
export const syncDataToOdoo = async (matchId) => {
  try {
    const response = await api.post(
      `/operator/matches/${matchId}/orders/sync-odoo`
    );
    return response?.data;
  } catch (error) {
    console.log("Error", error);
  }
};

// Update scores
export const updateScores = async (matchId, params) => {
  try {
    const response = await api.post(`/operator/matches/${matchId}/scored`, [
      ...params,
    ]);

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

// Settings
export const getSettings = async () => {
  try {
    const response = await api.get(`operator/setting`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const patchSettings = async (params) => {
  try {
    const response = await api.patch(`operator/setting`, {
      data: [...params],
    });

    return response;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getStatistics = async (matchId) => {
  try {
    const response = await api.get(`/matches/${matchId}/statistics`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getDuplicateCheckedIn = async (matchId) => {
  try {
    const response = await api.get(`/matches/${matchId}/duplicate`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const getOdooData = async () => {
  try {
    const response = await api.get(`/operator/orders/waiting-odoo`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const postOdooData = async () => {
  try {
    const response = await api.post(`/operator/orders/sync-all-odoo`);

    return response.data;
  } catch (error) {
    console.log("Error", error);
  }
};

export const exportHistoriesCheckedIn = async (matchId) => {
  try {
    return await api.post(
      `matches/${matchId}/check-in-histories/excel`,
      {},
      {
        responseType: "blob",
      }
    );
  } catch (error) {
    console.log("Error", error);
  }
};
