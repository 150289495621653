import { Routes, Route } from "react-router-dom";
import Home from "./screens/Home";
import Login from "./screens/Login";
import Clubs from "./screens/Clubs";
import NewClub from "./screens/Clubs/NewClub";
import PrivateRoute from "./components/PrivateRoute";
import { ConfigProvider } from "antd";
import useLayout from "./utils/useLayout";
import enUSIntl from "antd//lib/locale/en_US";
import Leagues from "./screens/Leagues";
import NewLeague from "./screens/Leagues/NewLeague";
import Stadiums from "./screens/Stadiums";
import NewStadium from "./screens/Stadiums/NewStadium.js";
import Matches from "./screens/Matches";
import MatchDetail from "./screens/Matches/MatchDetail";
import NewMatch from "./screens/Matches/NewMatch";
import Users from "./screens/Users";
import NewUser from "./screens/Users/NewUser";
import ClubDetails from "./screens/Clubs/ClubDetails";
import EditClub from "./screens/Clubs/EditClub";
import LeagueDetails from "./screens/Leagues/LeagueDetails";
import EditLeague from "./screens/Leagues/EditLeague";
import EditStadium from "./screens/Stadiums/EditStadium";
import NewTicketType from "./screens/Matches/NewTicketType";
import StadiumDetails from "./screens/Stadiums/StadiumDetails";
import UserDetails from "./screens/Users/UserDetails";
import EditUser from "./screens/Users/EditUser";
import Tickets from "./screens/Tickets";
import EditMatch from "./screens/Matches/EditMatch";
import NewGate from "./screens/Stadiums/NewGate";
import OTPs from "./screens/OTPs";
// import OrderRequests from "./screens/OrderRequests";
import Settings from "./screens/Settings";

const App = () => {
  const WithLayoutRoutes = useLayout(() => (
    <PrivateRoute>
      <Routes>
        <Route path="/clubs" element={<Clubs />} />
        <Route path="/clubs/new" exact element={<NewClub />} />
        <Route path="/clubs/:id" exact element={<ClubDetails />} />
        <Route path="/clubs/:id/edit" exact element={<EditClub />} />
        <Route path="/leagues" exact element={<Leagues />} />
        <Route path="/leagues/new" exact element={<NewLeague />} />
        <Route path="/leagues/:id" exact element={<LeagueDetails />} />
        <Route path="/leagues/:id/edit" exact element={<EditLeague />} />
        <Route path="/stadiums" exact element={<Stadiums />} />
        <Route path="/stadiums/new" exact element={<NewStadium />} />
        <Route path="/stadiums/:id" exact element={<StadiumDetails />} />
        <Route path="/stadiums/:id/edit" exact element={<EditStadium />} />
        <Route
          path="/stadiums/:stadiumId/gates/new"
          exact
          element={<NewGate />}
        />
        <Route path="/matches" exact element={<Matches />} />
        <Route path="/matches/:matchId" exact element={<MatchDetail />} />
        <Route path="/matches/:matchId/edit" exact element={<EditMatch />} />
        <Route
          path="/matches/:matchId/ticket-types/new"
          exact
          element={<NewTicketType />}
        />
        <Route path="/matches/:matchId/tickets" exact element={<Tickets />} />
        <Route path="/matches/new" exact element={<NewMatch />} />
        <Route path="/users" exact element={<Users />} />
        <Route path="/users/new" exact element={<NewUser />} />
        <Route path="/users/:id" exact element={<UserDetails />} />
        <Route path="/users/:id/edit" exact element={<EditUser />} />
        <Route path="/sms-otp" exact element={<OTPs />} />
        {/* <Route path="/order-requests" exact element={<OrderRequests />} /> */}
        <Route path="/settings" exact element={<Settings />} />
      </Routes>
    </PrivateRoute>
  ));

  return (
    <ConfigProvider locale={enUSIntl}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" exact element={<WithLayoutRoutes />}></Route>
      </Routes>
    </ConfigProvider>
  );
};

export default App;
