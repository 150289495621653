import {
  ApartmentOutlined,
  SkinOutlined,
  UserOutlined,
  RollbackOutlined,
  ProfileOutlined,
  HomeOutlined,
  MailOutlined,
  SettingOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  route: {
    path: "/",
    routes: [
      // {
      //   path: "/dashboard",
      //   name: "Dashboard",
      //   icon: <SmileFilled />,
      // },
      {
        path: "/clubs",
        name: "Clubs",
        icon: <SkinOutlined />,
      },
      {
        name: "Stadiums",
        path: "/stadiums",
        icon: <HomeOutlined />,
      },
      {
        name: "Matches",
        path: "/matches",
        icon: <ApartmentOutlined />,
      },
      {
        name: "Leagues",
        path: "/leagues",
        icon: <ProfileOutlined />,
      },
      {
        name: "Users",
        path: "/users",
        icon: <UserOutlined />,
      },
      {
        name: "OTPs",
        path: "/sms-otp",
        icon: <MailOutlined />,
      },
      // {
      //   name: "Order Requests",
      //   path: "/order-requests",
      //   icon: <UnorderedListOutlined />,
      // },
      {
        name: "Settings",
        path: "/settings",
        icon: <SettingOutlined />,
      },
      {
        name: "Logout",
        path: "/logout",
        icon: <RollbackOutlined />,
      },
    ],
  },
  location: {
    pathname: "/",
  },
};
