import { Button, Divider, Drawer, Modal } from "antd";
import { useCallback, useState } from "react";
import TicketTypeDetails from "../TicketTypeDetails";
import EditTicketType from "../EditTicketType";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { deleteTicketType, getMatch } from "../../../api";
import { useParams } from "react-router-dom";

const TicketTypeDraw = ({
  open,
  ticketData,
  cancel = () => {},
  setTicketTypes,
}) => {
  const { matchId } = useParams();

  const [isEditTicket, setIsEditTicket] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);

  const handleChangeDrawState = () => setIsEditTicket(true);

  const showModal = () => setIsShowModal(true);

  const handleCancel = () => setIsShowModal(false);

  const handleSubmitModal = async () => {
    try {
      const response = ticketData?.hided
        ? console.log("Restored record")
        : await deleteTicketType(ticketData?.id);

      if (response.status === 200) {
        await fetchTicketTypes();
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      cancel();
      handleCancel();
    }
  };

  const fetchTicketTypes = useCallback(async () => {
    try {
      const response = await getMatch(matchId);

      setTicketTypes(response?.ticketTypes);
    } catch (error) {
      console.log("Error", error);
    }
  }, [matchId, setTicketTypes]);

  return (
    <Drawer
      title="Ticket type details"
      width={700}
      closable={false}
      onClose={() => {
        setIsEditTicket(false);
        cancel();
      }}
      open={open}
    >
      {!isEditTicket ? (
        <>
          <TicketTypeDetails ticketId={ticketData?.id} />

          <Divider />

          <div className="ticket-types-actions-wrapper">
            <Button
              key="submit"
              type="primary"
              onClick={() => handleChangeDrawState()}
            >
              Edit ticket type
            </Button>

            {!ticketData?.hided ? (
              <Button
                className="icon-button"
                type="primary"
                danger
                onClick={() => showModal()}
              >
                Delete
              </Button>
            ) : (
              <Button
                className="icon-button icon-button-restore"
                type="primary"
                onClick={() => showModal()}
              >
                Restore
              </Button>
            )}
          </div>
        </>
      ) : (
        <EditTicketType
          ticketId={ticketData?.id}
          setIsEditTicket={setIsEditTicket}
          setTicketTypes={setTicketTypes}
        />
      )}

      <Modal
        open={isShowModal}
        onOk={() => handleSubmitModal()}
        onCancel={(event) => handleCancel(event)}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          {ticketData?.deleted ? (
            <p>Are you sure to restore this record?</p>
          ) : (
            <p>Are you sure to delete this record?</p>
          )}
        </div>
      </Modal>
    </Drawer>
  );
};

export default TicketTypeDraw;
