import { PlusOutlined } from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import { Typography, Button, Modal, Spin } from "antd";
import { ProTable, PageContainer } from "@ant-design/pro-components";
import { exportUsers, getUsers } from "../../api";
import dayjs from "dayjs";
import { useNavigate } from "react-router";
import {
  FileTextFilled,
  EditFilled,
  CheckCircleOutlined,
  MinusCircleOutlined,
  DownloadOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../utils/sortingData";
import useEventEnter from "../../utils/useEventEnter";
import { useForm } from "antd/lib/form/Form";

const { Text } = Typography;

const Users = () => {
  const navigate = useNavigate();
  const [form] = useForm();

  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    isActive: false,
    roles: "",
    startDate: "",
    endDate: "",
  });
  const [isShowExportModal, setIsShowExportModal] = useState(false);
  const [totalItems, setTotalItems] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [isLoading, setIsLoading] = useState(false);

  const handleShowExportModal = () => setIsShowExportModal(true);
  const handleCloseExportModal = () => setIsShowExportModal(false);

  const fetchUsers = useCallback(
    async (filterParams = {}) => {
      setIsLoading(true);
      try {
        const response = await getUsers(
          currentPage,
          pageSize,
          filterUsers?.fullName ||
            filterUsers?.email ||
            filterUsers?.phoneNumber ||
            filterUsers?.isActive ||
            filterUsers?.roles ||
            filterUsers?.startDate ||
            filterUsers?.endDate
            ? filterUsers
            : filterParams
        );

        setUsers(response?.results);
        setTotalItems(response?.total);
      } catch (err) {
        console.log("err");
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, filterUsers, pageSize]
  );

  const handleSubmitFilter = (params) => {
    console.log("🚀 ~ params:", params);
    const formValues = form.getFieldsValue();
    console.log("🚀 ~ formValues:", formValues);

    setFilterUsers({
      ...filterUsers,
      fullName: params?.fullName || formValues?.fullName || "",
      email: params?.email || formValues?.email || "",
      phoneNumber: params?.phoneNumber || formValues?.phoneNumber || "",
      roles: params?.roles || formValues?.roles || "",
      isActive:
        params?.isActive?.toLowerCase() ||
        formValues?.isActive?.toLowerCase() ||
        "",
      startDate:
        params?.createdAt?.[0] && formValues?.createdAt?.[0]
          ? dayjs(params?.createdAt?.[0]).toISOString() ??
            dayjs(formValues?.createdAt?.[0]).toISOString()
          : "",
      endDate:
        params?.createdAt?.[1] && formValues?.createdAt?.[1]
          ? dayjs(params?.createdAt?.[1]).toISOString() ??
            dayjs(formValues?.createdAt?.[1]).toISOString()
          : "",
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterUsers({
      fullName: "",
      email: "",
      phoneNumber: "",
      roles: "",
      isActive: "",
      startDate: "",
      endDate: "",
    });
    setCurrentPage(1);
  };

  const handleExportToExcel = useCallback(async () => {
    setIsShowExportModal(false);

    try {
      const response = await exportUsers();
      const blob = response.data;
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = "users.xlsx";
      link.style.display = "none";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    } catch (error) {
      console.log("Export Excel Fail With Error---->", error);
    }
  }, []);

  useEventEnter(handleSubmitFilter);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const tableColumns = [
    {
      title: "Full name",
      dataIndex: "fullName",
      sorter: (current, next) => SortingText(current.fullName, next.fullName),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (current, next) => SortingText(current.email, next.email),
    },
    {
      title: "Roles",
      dataIndex: "roles",
      sorter: (current, next) => SortingText(current.roles, next.roles),
      valueEnum: {
        operator: {
          text: "Operator",
        },
        user: {
          text: "User",
        },
        "gate-operator": {
          text: "Gate Operator",
        },
      },
    },
    {
      title: "Gender",
      dataIndex: "gender",
      search: false,
      sorter: (current, next) => SortingText(current.gender, next.gender),
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      sorter: (current, next) =>
        SortingNumber(current.phoneNumber, next.phoneNumber),
    },
    {
      title: "Birth",
      dataIndex: "dateOfBirth",
      search: false,
      sorter: (current, next) =>
        SortingDate(current.dateOfBirth, next.dateOfBirth),
      render: (_, record) => {
        return <Text>{dayjs(record.dateOfBirth).format("YYYY-MM-DD")}</Text>;
      },
    },
    {
      title: "Is active",
      dataIndex: "isActive",
      valueEnum: {
        TRUE: {
          text: "TRUE",
        },
        FALSE: {
          text: "FALSE",
        },
      },
      render: (_, record) => {
        if (record?.isActive) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) => SortingDate(current.createdAt, next.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
    {
      title: "Actions",
      width: 100,
      search: false,
      render: (_, record) => [
        <div className="actions-wrapper">
          <Button
            className="icon-button"
            icon={<FileTextFilled />}
            onClick={() => navigate(`/users/${record?.id}`)}
          />
          <Button
            className="icon-button"
            icon={<EditFilled />}
            onClick={() => navigate(`/users/${record?.id}/edit`)}
          />
        </div>,
      ],
    },
  ];

  return (
    <PageContainer title="All Users">
      <Modal
        open={isShowExportModal}
        onOk={handleExportToExcel}
        onCancel={handleCloseExportModal}
        centered
      >
        <div className="delete-content">
          <ExclamationCircleFilled style={{ fontSize: "48px" }} />
          <p>Do you want to export users data to excel?</p>
        </div>
      </Modal>

      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" spin />,
          spinning: isLoading,
        }}
        scroll={{ x: 1400 }}
        request={({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);
          setPageSize(pageSize);

          return {
            // success,
            // total,
            filter,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        columns={tableColumns}
        dataSource={users}
        toolBarRender={() => [
          <Button
            key="button"
            icon={<PlusOutlined />}
            onClick={() => {
              navigate("/users/new");
            }}
            type="primary"
          >
            New User
          </Button>,
          <Button
            key="button"
            icon={<DownloadOutlined />}
            onClick={() => handleShowExportModal()}
            type="primary"
          >
            Export excel
          </Button>,
        ]}
        search={{
          labelWidth: "auto",
          form: form,
        }}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
      ></ProTable>
    </PageContainer>
  );
};

export default Users;
