import { ProTable } from "@ant-design/pro-components";
import { getTickets } from "../../api";
import { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router";
import { Select, Spin, Typography } from "antd";
import * as dayjs from "dayjs";
import {
  SortingDate,
  SortingNumber,
  SortingText,
} from "../../utils/sortingData";
import { useForm } from "antd/lib/form/Form";
import { PAYMENT_STATUS_OPTIONS, STATUS_OPTIONS } from "../../utils/constant";
import TicketDraw from "./TicketDetailsDraw";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Text } = Typography;

const MatchTickets = () => {
  const params = useParams();
  const [ticketForm] = useForm();

  const [tickets, setTickets] = useState([]);
  const [filterTickets, setFilterTickets] = useState({
    ticketNumber: "",
    status: "",
    paymentStatus: "",
    email: "",
    phoneNumber: "",
    orderId: "",
    sentToOdoo: "",
    startDate: "",
    endDate: "",
  });
  console.log("🚀 ~ filterTickets:", filterTickets);
  const [columnsStateMap, setColumnsStateMap] = useState({
    fullName: {
      show: false,
      order: 0,
    },
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalItems, setTotalItems] = useState(null);
  const [isShowTicketDetailsDraw, setIsShowTicketDetailsDraw] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const showTicketDraw = () => setIsShowTicketDetailsDraw(true);
  const cancelTicketDraw = () => setIsShowTicketDetailsDraw(false);

  const fetchTickets = useCallback(
    async (filterParams = {}) => {
      setIsLoading(true);
      try {
        const response = await getTickets(
          params.matchId,
          currentPage,
          pageSize,
          filterTickets?.ticketNumber ||
            filterTickets?.status ||
            filterTickets?.paymentStatus ||
            filterTickets?.email ||
            filterTickets?.orderId ||
            filterTickets?.sentToOdoo ||
            filterTickets?.phoneNumber ||
            filterTickets?.startDate ||
            filterTickets?.endDate
            ? filterTickets
            : filterParams
        );

        setTickets(response.results);
        setTotalItems(response.total);
      } catch (err) {
        console.log("err", err);
      } finally {
        setIsLoading(false);
      }
    },
    [currentPage, filterTickets, pageSize, params.matchId]
  );

  const handleSubmitFilter = (params) => {
    console.log("🚀 ~ params:", params);
    const formValues = ticketForm.getFieldsValue();
    console.log("🚀 ~ formValues:", formValues);

    setFilterTickets({
      ...filterTickets,
      ticketNumber: params?.ticketNumber || formValues?.ticketNumber || "",
      phoneNumber:
        params?.user?.phoneNumber || formValues?.user?.phoneNumber || "",
      status:
        params?.status?.length > 0 && formValues?.status?.length > 0
          ? JSON.stringify(params?.status) || JSON.stringify(formValues?.status)
          : "",
      paymentStatus:
        params?.paymentStatus?.length > 0 &&
        formValues?.paymentStatus?.length > 0
          ? JSON.stringify(params?.paymentStatus) ||
            JSON.stringify(formValues?.paymentStatus)
          : "",
      email: params?.mailSendPaytap || formValues?.mailSendPaytap || "",
      orderId: params?.orderId || formValues?.orderId || "",
      sentToOdoo:
        params?.sentToOdoo?.toLowerCase() ||
        formValues?.sentToOdoo?.toLowerCase() ||
        "",
      startDate:
        params?.createdAt?.[0] && formValues?.createdAt?.[0]
          ? dayjs(params?.createdAt?.[0]).toISOString() ??
            dayjs(formValues?.createdAt?.[0]).toISOString()
          : "",
      endDate:
        params?.createdAt?.[1] && formValues?.createdAt?.[1]
          ? dayjs(params?.createdAt?.[1]).toISOString() ??
            dayjs(formValues?.createdAt?.[1]).toISOString()
          : "",
    });

    setCurrentPage(1);
  };

  const handleResetFilter = () => {
    setFilterTickets({
      ticketNumber: "",
      status: "",
      paymentStatus: "",
      email: "",
      phoneNumber: "",
      orderId: "",
      sentToOdoo: "",
      startDate: "",
      endDate: "",
    });
    setCurrentPage(1);
  };

  const handleRowOnClick = (record, rowIndex) => {
    showTicketDraw();
    setSelectedTicket(record);
  };

  useEffect(() => {
    fetchTickets();
  }, [fetchTickets]);

  const columns = [
    {
      title: "Ticket Number",
      dataIndex: "ticketNumber",
      sorter: (current, next) =>
        SortingText(current.ticketNumber, next.ticketNumber),
    },
    {
      title: "Status",
      dataIndex: "status",
      valueType: "select",
      sorter: (current, next) => SortingText(current.status, next.status),
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        const status = form.getFieldValue("ticketNumber");
        if (status !== "open") {
          return (
            <Select
              {...fieldProps}
              mode="multiple"
              allowClear
              placeholder="Please select"
              // onChange={handleChange}
              options={STATUS_OPTIONS}
            />
          );
        }
        return defaultRender(_);
      },
    },
    {
      title: "Payment status",
      dataIndex: "paymentStatus",
      sorter: (current, next) =>
        SortingNumber(current?.paymentStatus, next?.paymentStatus),
      renderFormItem: (
        _,
        { type, defaultRender, formItemProps, fieldProps, ...rest },
        form
      ) => {
        if (type === "form") {
          return null;
        }
        const status = form.getFieldValue("ticketNumber");
        if (status !== "open") {
          return (
            <Select
              {...fieldProps}
              mode="multiple"
              allowClear
              placeholder="Please select"
              // onChange={handleChange}
              options={PAYMENT_STATUS_OPTIONS}
            />
          );
        }
        return defaultRender(_);
      },
    },
    {
      title: "Order number",
      dataIndex: "orderNumber",
      search: false,
      sorter: (current, next) =>
        SortingNumber(
          current?.orderTickets?.[0]?.order?.orderNumber,
          next?.orderTickets?.[0]?.order?.orderNumber
        ),
      render: (_, record) => (
        <Text>{record?.orderTickets?.[0]?.order?.orderNumber}</Text>
      ),
    },
    {
      title: "Tap Transaction ID",
      dataIndex: "orderId",
      sorter: (current, next) =>
        SortingNumber(
          current?.orderTickets?.[0]?.order?.id,
          next?.orderTickets?.[0]?.order?.id
        ),
      render: (_, record) => (
        <Text>{record?.orderTickets?.[0]?.order?.id}</Text>
      ),
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      search: false,
      sorter: (current, next) =>
        SortingText(current?.user?.fullName, next?.user?.fullName),
      render: (_, record) => {
        return (
          <Text>{record?.user?.fullName ? record?.user?.fullName : "-"}</Text>
        );
      },
    },
    {
      title: "Email to Tap",
      dataIndex: "mailSendPaytap",
      sorter: (current, next) =>
        SortingText(current?.mailSendPaytap, next?.mailSendPaytap),
    },
    {
      title: "Send to Odoo",
      dataIndex: "sentToOdoo",
      valueEnum: {
        TRUE: {
          text: "TRUE",
        },
        FALSE: {
          text: "FALSE",
        },
      },
      render: (_, record) => {
        if (record?.sentToOdoo) {
          return (
            <CheckCircleOutlined
              style={{
                color: "green",
              }}
            />
          );
        }

        return (
          <MinusCircleOutlined
            style={{
              color: "red",
            }}
          />
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phoneNumber"],
      sorter: (current, next) =>
        SortingText(current?.user?.phoneNumber, next?.user?.phoneNumber),
    },
    {
      title: "Ticket Type",
      dataIndex: ["ticketType", "nameAr"],
      search: false,
      sorter: (current, next) =>
        SortingText(current?.ticketType?.nameAr, next?.ticketType?.nameAr),
    },
    {
      title: "Price",
      dataIndex: "price",
      search: false,
      sorter: (current, next) => SortingNumber(current?.price, next?.price),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      valueType: "dateTimeRange",
      fieldProps: {
        placeholder: ["Start time", "End time"],
        showTime: { format: "HH:mm" },
      },
      sorter: (current, next) =>
        SortingDate(current?.createdAt, next?.createdAt),
      render: (_, record) => {
        return (
          <Text>
            {dayjs(record?.createdAt)
              .tz("Asia/Kuwait")
              .format("YYYY/MM/DD HH:mm")
              .toString()}
          </Text>
        );
      },
    },
  ];

  return (
    <>
      <ProTable
        rowKey="id"
        loading={{
          indicator: <Spin className="spin-icon" spin />,
          spinning: isLoading,
        }}
        columns={columns}
        dataSource={tickets}
        request={async ({ pageSize, current }, sort, filter) => {
          console.log("submit filter", filter);
          console.log("submit pageSize", pageSize);
          console.log("submit current", current);
          setPageSize(pageSize);

          // await fetchTickets();
          return {
            // success,
            // total,
          };
        }}
        onSubmit={(params) => handleSubmitFilter(params)}
        onReset={handleResetFilter}
        scroll={{ x: 2000 }}
        search={{
          labelWidth: "auto",
          form: ticketForm,
          // defaultCollapsed: false,
        }}
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
        pagination={{
          pageSize: pageSize,
          total: totalItems,
          onChange: (page) => setCurrentPage(page),
        }}
        onRow={(record, rowIndex) => {
          return {
            onDoubleClick: () => handleRowOnClick(record, rowIndex),
          };
        }}
      ></ProTable>

      <TicketDraw
        open={isShowTicketDetailsDraw}
        cancel={cancelTicketDraw}
        ticketData={selectedTicket}
        tickets={tickets}
        setTickets={setTickets}
      />
    </>
  );
};

export default MatchTickets;
