import React, { useCallback, useEffect, useState } from "react";
import {
  ProForm,
  ProFormText,
  PageContainer,
  ProFormMoney,
  ProFormDigit,
  ProFormSelect,
} from "@ant-design/pro-components";
import { useNavigate, useParams } from "react-router";
import {
  createTicketType,
  getClubsOfMatch,
  getMatch,
  getGatesByStadium,
} from "../../../api";
import { message } from "antd";
import { TICKET_TYPE_STATUSES } from "../../../utils/constant";

const NewTicketType = () => {
  const [form] = ProForm.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const { matchId } = useParams();

  const [clubs, setClubs] = useState([]);
  const [match, setMatch] = useState(null);
  const [gates, setGates] = useState(null);
  const [isClubSelected, setIsClubSelected] = useState(false);

  const handleSelectClub = (value) => {
    try {
      if (value === "choose") {
        return setIsClubSelected(false);
      }

      setIsClubSelected(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const response = await createTicketType(
          matchId,
          values?.clubId?.includes("choose") || !values?.clubId
            ? { ...values, clubId: null, netPrice: 0 }
            : values
        );
        if (response.status === 201) {
          navigate(`/matches/${matchId}`);
          messageApi.success("Create ticket type successfully! My friends");
        }
      } catch (error) {
        console.log("Error", error);
      }
    },
    [matchId, messageApi, navigate]
  );

  const fetchClubsOfMatch = useCallback(async () => {
    try {
      const response = await getClubsOfMatch(matchId);

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));

      setClubs([{ value: "choose", label: "Choose..." }, ...customizeData]);
    } catch (error) {
      console.log("Error", error);
    }
  }, [matchId]);

  const fetchMatch = useCallback(async () => {
    try {
      const response = await getMatch(matchId);

      setMatch(response);
    } catch (error) {
      console.log("error", error);
    }
  }, [matchId]);

  const fetchGatesByStadium = useCallback(async () => {
    try {
      const response = await getGatesByStadium(match?.stadiumId);

      const customizeData = response.map((item) => ({
        value: item.id,
        label: `${item.nameEn} - ${item.nameAr}`,
      }));

      setGates(customizeData);
    } catch (err) {
      console.log("err", err);
    }
  }, [match?.stadiumId]);

  useEffect(() => {
    fetchClubsOfMatch();
    fetchMatch();
  }, [fetchClubsOfMatch]);

  useEffect(() => {
    if (match && match?.stadiumId) {
      fetchGatesByStadium();
    }
  }, [fetchGatesByStadium, match]);

  return (
    <PageContainer title="New Ticket Type">
      {contextHolder}
      <ProForm
        name="createTicketType"
        layout="horizontal"
        form={form}
        onFinish={handleSubmit}
      >
        <ProForm.Group>
          <div className="form-fields-wrapper">
            <ProFormText
              name="nameEn"
              label="Name in English"
              width={"lg"}
              rules={[{ required: true }]}
            />

            <ProFormText
              name="nameAr"
              label="Name in Arabic"
              width={"lg"}
              rules={[{ required: true }]}
            />

            <ProFormMoney
              name="price"
              label="Price"
              width={"lg"}
              localeCustomSymbol={"SA"}
              min={0}
              customSymbol={"SAR"}
              onChange={(value) => form.setFieldsValue({ netPrice: value })}
              rules={[{ required: true }]}
            />

            <ProFormSelect
              name="clubId"
              width={"lg"}
              label="Club"
              placeholder="Choose club"
              options={clubs}
              onChange={handleSelectClub}
            />

            <ProFormSelect
              name="gateIds"
              mode="multiple"
              // multiple={true}
              width={"lg"}
              label="Enter gates"
              placeholder="Choose allowed enter gates"
              options={gates}
            />

            {isClubSelected && (
              <ProFormMoney
                name="netPrice"
                label="Override price"
                width={"lg"}
                localeCustomSymbol={"SA"}
                min={0}
                customSymbol={"SAR"}
                rules={[{ required: true }]}
              />
            )}

            <ProFormDigit
              name="limitForUser"
              label="Limit buying tickets"
              width={"lg"}
              min={1}
              rules={[{ required: true }]}
            />

            <ProFormDigit
              name="remainingTicketQuantity"
              label="Amount of tickets available"
              width={"lg"}
              min={0}
              rules={[{ required: true }]}
            />

            <ProFormSelect
              name="ticketTypeStatus"
              // mode="multiple"
              // multiple={true}
              width={"lg"}
              label="Status"
              placeholder="Choose ticket type status"
              options={TICKET_TYPE_STATUSES}
            />
          </div>
        </ProForm.Group>
      </ProForm>
    </PageContainer>
  );
};

export default NewTicketType;
