import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getUserData } from "../../../api";
import {
  PageContainer,
  ProDescriptions,
  ProTable,
} from "@ant-design/pro-components";
import dayjs from "dayjs";
import { Card, Divider, Typography } from "antd";
import { isEmpty } from "lodash";
import { CheckCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { SortingDate } from "../../../utils/sortingData";

const UserDetails = () => {
  const { id } = useParams();

  const [userData, setUserData] = useState([]);
  const [userTickets, setUserTickets] = useState([]);
  console.log("🚀 ~ userTickets:", userTickets);
  const [columnsStateMap, setColumnsStateMap] = useState({
    matchNameEn: {
      show: false,
      order: 0,
    },
  });

  const fetchUserData = useCallback(async () => {
    try {
      const response = await getUserData(id);

      setUserData(response);
      setUserTickets(response?.tickets);
    } catch (error) {
      console.log("Error", error);
    }
  }, [id]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  return (
    <>
      {userData && (
        <PageContainer title={`User details: ${userData?.fullName}`}>
          <Card title="User Club">
            <ProDescriptions column={1}>
              <ProDescriptions.Item
                label="Club ID"
                valueType="text"
                span={2}
                contentStyle={{
                  maxWidth: "80%",
                }}
              >
                {userData?.clubId}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="User Information">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="User ID" valueType="text">
                {userData?.id}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Full name" valueType="text">
                {userData?.fullName}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Email" valueType="text">
                {userData?.email}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Phone number" valueType="text">
                {userData?.phoneNumber}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Gender" valueType="text">
                {userData?.gender}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Date of birth" valueType="text">
                {dayjs(userData?.dateOfBirth)
                  .tz("Asia/Kuwait")
                  .format("YYYY/MM/DD")
                  .toString()}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="User Role">
            <ProDescriptions column={1}>
              <ProDescriptions.Item label="Role" valueType="text">
                {userData?.roles}
              </ProDescriptions.Item>

              <ProDescriptions.Item label="Created at">
                {dayjs(userData?.createdAt).format("YYYY-MM-DD HH:mm")}
              </ProDescriptions.Item>
            </ProDescriptions>
          </Card>

          <Divider />

          <Card title="User Tickets">
            {userTickets && (
              <ProTable
                columns={[
                  {
                    key: "Ticket number",
                    title: "Ticket number",
                    dataIndex: "ticketNumber",
                  },
                  {
                    title: "Club name",
                    render: (_, record) => (
                      <Typography>
                        {record?.ticketType?.club?.nameEn}&nbsp;-&nbsp;
                        {record?.ticketType?.club?.nameAr}
                      </Typography>
                    ),
                  },
                  {
                    title: "Match name in Arabic",
                    render: (_, record) => (
                      <Typography>{record?.match?.nameAr}</Typography>
                    ),
                  },
                  {
                    title: "Match name in English",
                    dataIndex: "matchNameEn",
                    render: (_, record) => (
                      <Typography>{record?.match?.nameEn}</Typography>
                    ),
                  },
                  {
                    title: "Payment status",
                    dataIndex: "paymentStatus",
                  },
                  {
                    title: "Status",
                    dataIndex: "status",
                  },
                  {
                    title: "Price",
                    dataIndex: "price",
                  },
                  {
                    title: "Sent to Odoo",
                    dataIndex: "sentToOdoo",
                    render: (_, record) => {
                      return record?.hided ? (
                        <CheckCircleOutlined
                          style={{
                            color: "green",
                          }}
                        />
                      ) : (
                        <MinusCircleOutlined
                          style={{
                            color: "red",
                          }}
                        />
                      );
                    },
                  },
                  {
                    title: "Created at",
                    dataIndex: "createdAt",
                    defaultSortOrder: "ascend",
                    sorter: (current, next) =>
                      SortingDate(current.createdAt, next.createdAt),
                    render: (_, record) => (
                      <Typography>
                        {dayjs(record?.createdAt)
                          .tz("Asia/Kuwait")
                          .format("YYYY/MM/DD HH:mm")
                          .toString()}
                      </Typography>
                    ),
                  },
                ]}
                dataSource={userTickets}
                search={false}
                scroll={{ x: 1400 }}
                // onRow={(record, rowIndex) => {
                //   return {
                //     onClick: () => handleRowOnClick(record, rowIndex),
                //   };
                // }}
                columnsState={{
                  value: columnsStateMap,
                  onChange: setColumnsStateMap,
                }}
              />
            )}
          </Card>
        </PageContainer>
      )}
    </>
  );
};

export default UserDetails;
